import { FaFacebook, FaLinkedin, FaGoogle } from 'react-icons/fa';
import { AiFillTwitterCircle } from 'react-icons/ai';

export const icons = [
    {
      icon: FaLinkedin,name:'LinkedIn',
      info: 'Browse and apply for jobs listed by parents',
      color: '#0077b5',
    },
    { icon: FaFacebook,name:'Facebook', info: 'Simply register for free', color: '#4267B2' },
    { icon: AiFillTwitterCircle,name:'Twitter', info: 'Create your profile', color: '#00acee' },
    
    {
      icon: FaGoogle,name:'Google',
      info: 'Browse and apply for jobs listed by parents',
      color: '#0077b5',
    },
  ];

export const link= {
    body:'Do you already have an existing account on CaregiverAfrica? select account or new account',
    title:'Link/Register Account',
    action:'Proceed',
    data:[
    {
      name: 'link',
      type: 'select',
      payload: icons.reduce((cal,{name})=>{
        if(name!=='LinkedIn'){
          return [...cal,name]
        }else{
          return cal
        }
      },[]),
      val:'Facebook'
    },
   
  ]}



  