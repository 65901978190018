

import child from '../assets/images/124264382_m.jpg';
import love from '../assets/images/132164169_m.jpg';
import meals from '../assets/images/128969356_m.jpg';
import hostipalcare from '../assets/images/18736834_m.jpg';

import nanny from '../assets/images/118728302_m.jpg';
import governess from '../assets/images/54383232_m.jpg';
import governor from '../assets/images/107970849_m.jpg';
import house from '../assets/images/161918315_m.jpg';
import nisuery from '../assets/images/158633966_m.jpg';
import cache from '../assets/images/74768811_m.jpg';
import needs from '../assets/images/140558545_m.jpg';
import weekend from '../assets/images/126760686_m.jpg';
import pair from '../assets/images/90177085_m.jpg';
import couple from '../assets/images/126119375_m.jpg';
import pro from '../assets/images/121723522_m.jpg';
import assistant from '../assets/images/42398864_m.jpg';
import after from '../assets/images/116619799_m.jpg';
import babysitter from '../assets/images/146606063_m.jpg';
import manny from '../assets/images/117788456_m.jpg';
import maternity from '../assets/images/168606914_m.jpg';
import fun from '../assets/images/116422812_m.jpg';

import old from '../assets/images/119507688_m.jpg';
import homecare from '../assets/images/168615980_m.jpg';
import custom from '../assets/images/111274127_m.jpg';
import fam from '../assets/images/42314769_m.jpg';

import kidding from '../assets/images/166433705_m.jpg';

//import { encodeImageToBlurhash } from '../utils/HarshImage';
export const homeBgs = [{
  bg:love,
  hashString:  `UuP#AJX9}%sRnMV[bcs:xsj[NJa#xYj?RkWC`
}, 
{
  bg:child,
  hashString:  `UTMQR}^+?aMd.8sls:IU_NR+M{WWxGRiM_s.`
}, 
{
  bg:kidding,
  hashString:  `UWK^]OIU?wnh9tIUMwNGRPRkoz%LV@x]RPt7`
}, 
{
  bg:fam,
  hashString:  `UMKJ_J00D$xw%d_2IBbp.mIoR%t6_MRjROR.`
}];


/* encodeImageToBlurhash(makeup).then(res=>{
  console.log('hashString ',res);
}) */

export const carer = [
  {
    bg: nanny,
    hashString:  `UPLg^Y4o4._M~VIoM{tR9saKR*bcMdogM{jE`,
    title: 'Nanny',
    info: {
      about: `A Nanny is a person who is responsible for the care of their employers' children. They are trained and experienced in child development and are in charge of a child's physical, social, emotional, and educational needs.

      A nanny is typically responsible for all aspects of child care. They are the children's loving, nurturing, and trustworthy companions in the home.

Many African families chose to hire an English-speaking nanny in order to provide their children with a good upbringing and a bilingual environment in which to learn.`,
      duties: [
        'Nannies are employed to work in collaboration with families to provide care and educational development for children aged birth to seven years.',
        "Nannies take care of children's physical needs, such as dressing, feeding, and bathing.",
        "Nannies are occasionally expected to perform light household duties such as children's laundry, meal preparation, and maintaining the cleanliness of the children's room and belongings.",
        'Nannies may be expected to teach basic social, reading and writing skills to older children in the household.',
        'Nannies may be expected to teach older children in the household basic social, reading, and writing skills.',
        'Nannies may be required to accompany children to and from school as well as supervise homework.',
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },

   {
    bg: governess,
    hashString:  `UEH-uz-:_NI[?88_-;tmjKI^8_wGxuM{IU%L`,
    title: 'Governess',
    info: {
      about: `A Governess is a trained professional who is hired by parents to educate their children in their private home. Governesses are highly educated women who serve as both teachers and academic mentors to the children. Generally, Governesses are not in charge of general caregiving but are primarily concerned with education.`,
      duties: [
        'A Governess is a teacher and academic mentor who works with children in private homes to supplement or replace their school education.',
        'A Governess fosters intellectual, educational, and social growth.',
        'A Governess plans and implements extracurricular activities and educational excursions.',
        'A qualified Governess will have a teaching background or higher childcare education, as well as at least three years of professional childcare or teaching experience.',
        'A bilingual Governess instructs in a second language (French, Spanish, Italian, Russian, etc).',
        'A Governess may also specialise and teach Music, Arts, Sports or Languages.',
        'A Governess is responsible for teaching etiquette and promoting good manners and behavior.',
      ],
      working_hours:
        'Each family will develop their own schedule and shift pattern. Most Governor-Governesses work an average of 8 to 10 hours per day, with two days off per week. Different shift patterns, such as one week on/one week off, can sometimes be agreed upon. A Governess is usually expected to accompany the family on their journey.',
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: governor,
    hashString:  `UKNc{C.SkGRhRq_N-qM_E2%0Mw9G4mM|xvIV`,
    title: 'Governor',
    info: {
      about: `A Governor is a trained professional who is hired by parents to educate their children in their private home. Governores are highly educated women who serve as both teachers and academic mentors to the children. Generally, Governores are not in charge of general caregiving but are primarily concerned with education.`,
      duties: [
        'A Governor is a teacher and academic mentor who works with children in private homes to supplement or replace their school education.',
        'A Governor fosters intellectual, educational, and social growth.',
        'A Governor plans and implements extracurricular activities and educational excursions.',
        'A qualified Governor will have a teaching background or higher childcare education, as well as at least three years of professional childcare or teaching experience.',
        'A bilingual Governor instructs in a second language (French, Spanish, Italian, Russian, etc).',
        'A Governor may also specialise and teach Music, Arts, Sports or Languages.',
        'A Governor is responsible for teaching etiquette and promoting good manners and behavior.',
      ],
      working_hours:
        'Each family will develop their own schedule and shift pattern. Most Governors work an average of 8 to 10 hours per day, with two days off per week. Different shift patterns, such as one week on/one week off, can sometimes be agreed upon. A Governess is usually expected to accompany the family on their journey.',
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },

 {
    bg: house,
    hashString:  `UWM%7b?w_N?Z%NadR%M~Kjt2nNj0%MRjaejY`,
    title: 'Nanny-Housekeeper',
    info: {
      about: `A nanny housekeeper will typically care for children up to the age of 12 in addition to performing domestic duties. The role is flexible, allowing you and your family to decide on their responsibilities based on what works best for you, allowing you to spend more time doing the things you enjoy.
      
      This means that a nanny housekeeper can be solely responsible for child care, work alongside you, or focus primarily on housekeeping and only provide child care when you are otherwise occupied. A nanny housekeeper who is solely responsible for your children will complete household tasks while your children are at school or nursery, or on their schedule.`,
      duties: [
        `Getting children ready for school`,
        `Managing the school drop-off and pick-up`,
        `Homework supervision`,
        `Making plans for playdates`,
        `Making appointments`,
        `Taking care of the family's laundry`,
        `Meal preparation`,
        `Keeping the house clean`,
        `Managing any tradespeople who may be present at the house`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },

   {
    bg: nisuery,
    hashString:  `UUO3d{ROOF%NKkNHRPRO~q9FM{E1xWV=IUIV`,
    title: 'Nursery Worker',
    info: {
      about: `Nursery assistants are compassionate and patient caregivers for preschool-age children, attending to their daily needs and preparing them for kindergarten.
      
      Nursery assistants typically work in daycares, but they may also work in community groups, preschools, and other pre-kindergarten facilities.`,
      duties: [
        `Supervise and Monitor Children-Nursery assistants work hands-on to ensure a safe child care environment. Nursery assistants keep a close eye on the children while they are playing, eating, or napping to prevent accidents or injuries. Although not all facilities require it, many nursery assistants have CPR or first aid training in case of an emergency on the job.`,
        `Organize Daily Activities-Aside from safety, nursery assistants provide a fun and educational environment for the children in their care. They collaborate closely with their directors and supervisors to create interesting activities. If the nursery assistant also cares for children with disabilities, these programs are modified as needed to ensure that everyone feels included.`,
        `Keep and Update Records-Nursery assistants keep detailed logs of each child in their facility to meet a child's needs regardless of who is on duty. These logs may contain information such as a child's nursery attendance, temperament, food allergies, and any special accommodations the child requires.`,
        `Make Snacks and Meals-Nursery assistants work with their supervisors to prepare healthy, nutritious meals and snacks for the children. As new children arrive at their facility, they keep track of any allergies or dietary restrictions and provide meal alternatives as needed.`,
        `Resolve Conflicts Between Children-A nursery assistant carefully observes the children under their supervision in order to identify any conflicts that may arise. They mediate conflicts between children and teach them to compromise and respect one another. Nursery assistants also comfort hurt or upset children and de-escalate temper tantrums in the event of hurt feelings.`,
        `Maintain a Sanitary Environment-Nursery assistants are in charge of cleaning up messes and organizing materials for the next program after activities or mealtimes. They usually sanitize toys, kitchen tools, and other items before or after the children arrive. Nursery assistants are also in charge of monitoring each child's personal hygiene, such as changing dirty or soiled clothing as needed.`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nursery assistants earnings are dependent on age, experience and qualifications.',
    },
  },

  {
    bg: cache,
    hashString:  `UjQJZS-;tS9F~XNIt7xYD%RiRkt8%Noff5f5`,
    title: 'Creche Assistant',
    info: {
      about: `Working with vulnerable families in the community is a very rewarding job.
      
      The Crèche Worker is in charge of providing crèche services at various locations throughout the Walsall area.`,
      duties: [
        `Ensure the children's overall safety and well-being.`,
        `Create a nurturing and stimulating environment for the children.`,
        `Maintain a high level of hygiene on the premises.`,
        `Keep parents informed of their child's progress.`,
        `Ensure that all rooms are prepared and set up for the day.`,
        `Participate in all activities and contribute to their provision.`,
        `Inform the Crèche Facilitator of any issues that require their attention.`,
        `Keep any personal information about the children, their families, or others strictly confidential.`,
        `Ensure that all policies are followed.`,
        `Participate in relevant training as needed.`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Creche Assistant earnings are dependent on age, experience and qualifications.',
    },
  },

  {
    bg: needs,
    hashString: `UABV;N4n9aJT00?b%Msm.TD*IAR-mlxt-;ax`,
    title: 'Nanny (Special needs)',
    info: {
      about: `Special requirements Nannies are responsible for all aspects of the care of special-needs children. They are the children's loving, nurturing, and trustworthy companions. They are trained and experienced in child development and are in charge of a child's physical, social, emotional, and educational needs.`,
      duties: [
        `providing developmental activities that will make the child feel capable`,
        `avoiding excessive stimulation`,
        `boosting a child's self-esteem`,
        `teaching specific skills that will aid your child's development`,
        `teaching non-disabled children/siblings how to interact with a child with special needs`,
        `establishing objectives`,
        `providing opportunities and activities to support the child's strengths in order for him to feel successful and capable`,
        `Keep an eye out for signs of aggressive behavior and intervene as soon as possible.`,
        `educating students on problem-solving techniques`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: weekend,
    hashString:  `UZJ7:jRPxuoz.8IUxaxu~qofxaof%MRiNGjb`,
    title: 'Weekend Nanny',
    info: {
      about: `As a Weekend Nanny, you will be responsible for providing childcare as well as completing household tasks for parents. Part-time weekend nanny jobs typically require you to work a Saturday-Sunday shift. You can either commute to the house every day or spend the weekend there. Your responsibilities will differ depending on the specific needs of the children and parents for whom you work.`,
      duties: [
        'Weekend Nannies are employed to work in collaboration with families to provide care and educational development for children aged birth to seven years.',
        "Weekend Nannies take care of children's physical needs, such as dressing, feeding, and bathing.",
        "Weekend Nannies are occasionally expected to perform light household duties such as children's laundry, meal preparation, and maintaining the cleanliness of the children's room and belongings.",
        'Weekend Nannies may be expected to teach basic social, reading and writing skills to older children in the household.',
        'Weekend Nannies may be expected to teach older children in the household basic social, reading, and writing skills.',
        'Weekend Nannies may be required to accompany children to and from school as well as supervise homework.',
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a Weekend Nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },
 {
    bg: fun,
    hashString:  `UKD]l8%3j=M{~pxt%h%M_4xvi^s--;t7M_Rj`,
    title: 'Holiday Nanny',
    info: {
      about: `A Holiday nanny is in charge of traveling client's children. Typically, a Holiday nanny is responsible for all aspects of child care. They are the children's loving, nurturing, and trustworthy companions while the parent is away attending to other business on holiday .`,
      duties: [
        "Holiday Nannies take care of children's physical needs, such as dressing, feeding, and bathing.",
      ],
      working_hours: `Work hours will be determined by the client`,
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: pair,
    hashString:  `UgK^{TNHIU01~qM{M{RP-;RPV@t7%2M{ayxu`,
    title: 'Au Pair',
    info: {
      about: `An Au Pair's responsibilities vary depending on the needs of the Host Family.
      
      Before the Au Pair travels to the host country, these responsibilities should be determined and agreed upon. Host Families can make this official by using the Au Pair contract provided by AuPair.com, which will also be required if a visa is required.`,
      duties: [
        `Playing with the kids`,
        `Driving and picking up the kids from school, as well as other responsibilities`,
        `Cooking simple recipes`,
        `Keeping the kids' rooms neat and clean`,
        `Doing the children's laundry and ironing their clothes`,
        `Assisting children with their homework`,
        `Putting the children to bed`,
        `Assisting the host children with personal hygiene (tooth brushing, changing diapers etc.)`,
        `Simple shopping`,
        `Dishwasher loading and unloading`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Au Pair earnings are dependent on age, experience and qualifications.',
    },
  },

   {
    bg: couple,
    hashString:  `UTJ*9m00.7%2kq?HRiSh_NMxM{-;%2M|R%xa`,
    title: 'Jobs for couples',
    info: {
      about: `A Nanny is a person who is responsible for the care of their employers' children. They are trained and experienced in child development and are in charge of a child's physical, social, emotional, and educational needs.

      A nanny is typically responsible for all aspects of child care. They are the children's loving, nurturing, and trustworthy companions in the home.

Many African families chose to hire an English-speaking nanny in order to provide their children with a good upbringing and a bilingual environment in which to learn.`,
      duties: [
        'Nannies are employed to work in collaboration with families to provide care and educational development for children aged birth to seven years.',
        "Nannies take care of children's physical needs, such as dressing, feeding, and bathing.",
        "Nannies are occasionally expected to perform light household duties such as children's laundry, meal preparation, and maintaining the cleanliness of the children's room and belongings.",
        'Nannies may be expected to teach basic social, reading and writing skills to older children in the household.',
        'Nannies may be expected to teach older children in the household basic social, reading, and writing skills.',
        'Nannies may be required to accompany children to and from school as well as supervise homework.',
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: pro,
    hashString:  `U8FrFi~qGwtS.m4n^jV@lTM{#PRj^*?b?b?b`,
    title: 'Early Years Professional',
    info: {
      about: `An Early Years Professional works with young children in any school, nursery, or childcare setting.`,
      duties: [
        `Through play and teaching, we can encourage learning and development.`,
        `Creating a safe environment and using imagination to assist children in learning.`,
        `Collaborating with parents to help their children.`,
        `Providing opportunities for children to improve their literacy, language, and numeracy skills.`,
        `Keeping track of progress and devising educational games`,
        `Creating activities that will keep the kids entertained throughout the day.`,
        `Assisting children in preparing for the transition to a classroom environment.`,
        `Assisting children in becoming more self-sufficient in their daily activities.`,
        `Finding the right balance of activities and downtime.`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },

  {
    bg: assistant,
    hashString:  `UNK_5uu2_MMx.SV@%gIA%M%2Vstl^,-po3R+`,
    title: 'Nanny-Personal Assistant',
    info: {
      about: `A Happy Nest nanny-Personal Assistant takes on additional responsibilities to support the parents when they are not caring for the children in addition to standard nanny duties.
      
      Busy families appreciate this service, and some parents who run a home-based business incorporate some additional administrative duties into the role. The primary responsibility of the PA is to manage the parent(s)' professional and social needs (the employer). Many nannies find this a very rewarding role because they can use a variety of skills to support your children and manage the day-to-day operations.`,
      duties: [
        `Care for the children solely or in collaboration with the parents`,
        `Taking the kids to school, nursery, or playgroup`,
        `Cooking and household duties, maintaining household accounts, paying bills, dealing with other household staff such as gardeners, and taking deliveries are all examples of direct support provided to parents.`,
        `Some nanny-personal assistants may be employed on a live-in or live-out basis, depending on the level of support required by the family; their duties will be modified to reflect this.`,
        `Responding to invitations, scheduling appointments, and keeping track of the household calendar`,
        `Making travel arrangements, as well as assisting with the planning of other commitments`,
        `Help with errands like shopping and dry cleaning`,
        `Making reservations, placing orders, and responding to emails`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: after,
    hashString:  `U9Kwh7^K8^00uh4:-Cx]?cTKTe?b_2?wIAD*`,
    title: 'After School Nanny',
    info: {
      about: `Many families face a significant child care challenge in determining where their children will go after school, what they will do, and who will be watching them. As a result, some parents hire an after-school nanny or babysitter to fill the child care gap between 3 p.m. and 5 p.m. (or 6 p.m. or 7 p.m.) and lighten the load.`,
      duties: [
        `Transportation to and from after-school activities and sports for the child(ren).`,
        `Homework assistance or tutoring`,
        `Prepare snacks or a meal.`,
        `Take care of your pets.`,
        `Housekeeping, laundry, and other household chores`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Nannies earnings are dependent on age, experience and qualifications.',
    },
  },

  {
    bg: babysitter,
    hashString:  `URN,#p00~q_3?cRjIAx]RPt7ofM{RjWBogay`,
    title: 'Babysitter',
    info: {
      about: `Babysitters are frequently self-employed and work on a verbal contract for a short period of time. Long-term contracts and full-time employment are available, but are less common throughout the industry. In the absence of adults, babysitters are responsible for providing supervision and care to babies and children.`,
      duties: [
        `Oversee all children's safety and well-being.`,
        `Follow all schedules established by Parents, Guardians, or Educators.`,
        `Provide meals at predetermined times.`,
        `Participate in healthy educational and developmental activities with your children.`,
        `Conduct yourself as if you were a person of authority and worth.`,
        `If necessary, transport children to activities such as sports, art, or music classes in a safe manner.`,
        `Maintain a safe and clean care environment.`,
        `Maintain punctuality and, on occasion, stay late to assist with unforeseen issues.`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Babysitters earnings are dependent on age, experience and qualifications.',
    },
  },

  {
    bg: manny,
    hashString:  `UeIhNp~q?vM|%N%M%LoztRt7t6xaIURiM{Rj`,
    title: 'Manny',
    info: {
      about: `A Manny is the equivalent of a female nanny who is in charge of their employers' children. They are trained and experienced in child development and are in charge of a child's physical, social, emotional, and educational needs.
      
      Typically, a manny is responsible for all aspects of child care. They are the children's loving, nurturing, and trustworthy companions in the home.

Many families around the world chose to hire an English-speaking manny in order to provide their children with a "European" upbringing and a bilingual environment in which to learn.`,
      duties: [
        'Mannies are employed to work in collaboration with families to provide care and educational development for children aged birth to seven years.',
        "Mannies take care of children's physical needs, such as dressing, feeding, and bathing.",
        "Mannies are occasionally expected to perform light household duties such as children's laundry, meal preparation, and maintaining the cleanliness of the children's room and belongings.",
        'Mannies may be expected to teach basic social, reading and writing skills to older children in the household.',
        'Mannies may be expected to teach older children in the household basic social, reading, and writing skills.',
        'Mannies may be required to accompany children to and from school as well as supervise homework.',
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Mannies earnings are dependent on age, experience and qualifications.',
    },
  },

  {
    bg: maternity,
    hashString:  `UKMa6D00X800~p4U00x^9YIUIVofs.-;%gad`,
    title: 'Maternity Nurse',
    info: {
      about: `A Maternity Nurse is a trained and experienced professional who provides essential support, advice, care, and respite to parents and newborn babies. They ensure that the transition period following the birth of a new child is a pleasant, relaxed, and enjoyable experience for everyone involved.Their role gives parents and families time to rest and recover from the birth of their child, while also empowering parents to feel confident and capable of caring for their new child once the Maternity Nurse has left.
      
      A Maternity Nurse's primary responsibility is to care for the newborn child and to ensure the well-being of both mother and baby. They are not in charge of the care of other children in the household.`,
      duties: [
        `A Maternity Nurse advises on feeding the baby, bathing, sleeping, clothing, establishing routines, and physically performing all of these tasks as needed.`,
        `When needed, a Maternity Nurse works closely with the family to step in and provide respite.`,
        `If the baby is breastfed, the Maternity Nurse will monitor, encourage, and advise as needed.`,
        `If the baby is bottle-fed, the Maternity Nurse will make sure that all feeding equipment is clean, sterile, and ready to use at all times of the day and night. The Maternity Nurse is in charge of feeding the baby throughout the day and night, as well as establishing the best feeding routines in accordance with those agreed upon with the parents.`,
        `To give the mother time to rest, a Maternity Nurse should resettle the baby after feeds as needed.`,
        `A Maternity Nurse may be expected to help maintain the nursery's cleanliness.`,
        `Maternity nurses can help mothers who have been ill after giving birth, including those suffering from postnatal depression.`,
        `A Maternity Nurse will assist in integrating the new baby into a family with other children.`,
      ],
      working_hours: `Each family has its own set of requirements and schedule. During their contract, most Maternity Nurses live with the family.

        Maternity Nurse - provides 24-hour on-call care after the baby is born, working 6 or 7 days per week on a temporary basis.
        
        Daily Maternity Nurses – work up to 12 hours per day, six days per week. They are an excellent option for families who are unable to provide live-in care. The family will receive all of the Maternity Nurse's benefits and experience, but without the night-time support of a 24 hour nurse.
        
        Night-only Maternity Nurses – work up to 12 hours per night and up to 6 days per week and can provide great relief and support for parents looking for much needed sleep when the baby is waking frequently.

Maternity Nannies – take over from a Maternity Nurse and continue to provide all of the established daily care and routines. The nannies have prior experience with infants and young toddlers.`,
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: old,
    hashString:  `UPMGt~00?u?v~pMxo#xa-o%1X9M{tSozROjF`,
    title: 'Elderly Caregiver',
    info: {
      about: `Elderly Caregivers and Companions assist the elderly in aging at home by doing errands, light housekeeping, and offering assistance with daily duties that grow increasingly difficult as they age.`,
      duties: [
        `provide elderly with companionship and personal care in client’s home`,
        `care for the elderly during periods of incapacitation`,
        `plan and prepare meals`,
        `administer bedsides and personal care such as: aid in ambulation`,
        `bathing`,
        `personal hygiene and dressing and undressing`,
        `perform routine housekeeping duties such as laundry`,
        'washing dishes and making beds',
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average Elderly Caregiver earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: homecare,
    hashString:  `UbKBXV4oW?awxYs+%MoJ~poytRRjM{%gt7t7`,
    title: 'Home Patient Care',
    info: {
      about: `Home Patient Care includes any professional support services that allow a person to live safely in their home.In-home care services can aid someone who is aging and requires assistance to live independently, is dealing with chronic health problems, is recuperating from a medical setback, or has special requirements or a disability. Depending on a person's needs, professional caregivers such as nurses, assistants, and therapists provide short-term or long-term care in the home.`,
      duties: [
        `Assist with daily tasks including dressing and bathing.`,
        `Assisting with household activities in a safe manner`,
        `Services for therapy and rehabilitation`,
        `Administer bedsides and personal care such as: aid in ambulation`,
        `Companionship`,
        `Short- or long-term nursing care for an illness, disease, or disability—including tracheostomy and ventilator careg`,
      ],
      working_hours:
        "Each family will develop their own schedule and shift pattern. It is common for a nanny to be employed on a live-in or live-out basis, depending on the family's domestic circumstances.",
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: hostipalcare,
    hashString:  `UfLz{v~oWFoes6WFNGt7ROo#RORQtTxZt8Rj`,
    title: 'Hospital Patient Care',
    info: {
      about: `Home Patient Care's typical responsibilities include taking the temperature, blood pressure, pulse, and respiration of hospital patients; assisting patients with basic tasks such as bathing, dressing, and eating; preparing and administering some medications; collecting specimens for lab tests; and assisting patients with basic tasks such as bathing, dressing, and eating. patients' monitoring.`,
      duties: [
        `Assist with daily tasks including dressing and bathing.`,
        `Services for therapy and rehabilitation`,
        `Administer bedsides and personal care such as: aid in ambulation`,
        `Companionship`,
        `Short- or long-term nursing care for an illness, disease, or disability—including tracheostomy and ventilator careg`,
      ],
      working_hours:
        'Each family will develop their own schedule and shift pattern for patient care.',
      salary:
        'On average earnings are dependent on age, experience and qualifications.',
    },
  },
  {
    bg: meals,
    hashString:  `UxP?:b_4ITRjNGxHs:WCs:R*nhXSofn*oLWW`,
    title: 'Patient Meals',
    info: {
      about: `Provides enjoy safe, quality, nutritious meals that meet the patient's needs. Check our menu for more`,

      working_hours: 'Meals are provided when the patients needs them.',
      pricing: 'Pricing depends on the pricing of menu item selected.',
    },
  },
  {
    bg: custom,
    hashString:  `UWSrltWUaxVs*0tSj]ozyYR4aekVL}f+o#V@`,
    title: 'Custom Needs',
    info: {
      about: `If for any reason you are not able to find the service you are looking for among our listed packages, feel free to contact us and we review your unique requirements and where possible design a bespoke package for you.`,
    },
    action: 'Contact us',
  },
];