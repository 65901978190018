import { memo } from 'react';
import './splash.css';
import LogoSVG from '../logo/logoComp';

const SplashScreen = () => (
	<div className='App-header'>
		<LogoSVG />
	</div>
);

export default memo(SplashScreen);
