import { combineReducers } from 'redux';

// import keys from './keys_reducers';
import toggleReducer from './Toggle_menu';
import authReducer from './AuthReducer';
import storeReducer from './firestoreReducers';

// export default counter

export default combineReducers({
  cla_menuReducer: toggleReducer,
  cla_datastore: storeReducer,

  cla_authReducer: authReducer,
});
