export const URL_STR =
  process.env.NODE_ENV === "development" ? "" : "https://caregiverafrica.com/";
const ACC_CAREGIVER = "/my_account/caregiver/";
const ACC_PARENT = "/my_account/parent/";
export const languagesOpt = [
  "Not specified",
  "English",
  "French",
  "Portugese",
  "Swahili",
  "Arabic",
  "bemba",
  "kaonde",
  "lozi",
  "lunda",
  "luvale",
  "nyanja",
  "tonga",
];
export const selOpt = ["Not specified", "Yes", "No"];
export const sexOpt = ["Select sex", "Female", "Male"];
export const educationOpt = [
  "Select education level",
  "Junior secondary",
  "High school diploma",
  "College diploma",
  "College degree",
  "Post graduate",
];
export const workOpt = ["Either", "Full time", "Part time"];
export const liveOpt = ["Either", "Live in", "Live out"];
export const termOpt = ["Either", "Permanent", "Temporary"];
export const durationOpt = [
  "Less than 6 months",
  "Between 6 months and 12 months",
  "More than 6 months",
];
/* export const starterOpt = [
  "Immediately",
  "Within a week",
  "Within a month",
  "Within a 2 months",
  "Within a 3 months",
]; */
export const experienceOpt = [
  "Not specified",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "10+",
];
export const rangeOpt = [
  "Not specified",
  "Infant (0-11mos)",
  "Toddler (1-3yrs)",
  "Early school age (4-5yrs)",
  "School age (6+yrs)",
];
export const regLinks = [
  "Create a free parent account to find your caregiver",
  "Create a free caregiver account to find a job",
];

export const prices = [
  [
    {
      val: {
        USD: 5,
        ZMW: 1,
        KES: 668,
        RWF: 5537,
        UGX: 18657,
        GHS: 54,
        TZS: 11710,
        XAF: 3004,
        XOF: 3002,
      },
      title: "Standard",
      subtitle: "1 month",
    },
    {
      val: {
        USD: 15,
        ZMW: 10,
        KES: 1850,
        RWF: 16005,
        UGX: 54585,
        GHS: 135,
        TZS: 35010,
        XAF: 9262,
        XOF: 9262,
      },
      title: "Proffesional",
      subtitle: "3 months",
    },
    {
      val: {
        USD: 60,
        ZMW: 15,
        KES: 7401,
        RWF: 64020,
        UGX: 218343,
        GHS: 540,
        TZS: 140040,
        XAF: 37050,
        XOF: 37050,
      },
      title: "Ultimate",
      subtitle: "12 months",
    },
  ],
  [
    {
      val: {
        USD: 10,
        ZMW: 1,
        KES: 1233,
        RWF: 10670,
        UGX: 36390,
        GHS: 90,
        TZS: 23340,
        XAF: 6175,
        XOF: 6175,
      },
      title: "Standard",
      subtitle: "1 month",
    },
    {
      val: {
        USD: 15,
        ZMW: 10,
        KES: 1850,
        RWF: 16005,
        UGX: 54585,
        GHS: 135,
        TZS: 35010,
        XAF: 9262,
        XOF: 9262,
      },
      title: "Proffesional",
      subtitle: "3 months",
    },
    {
      val: {
        USD: 60,
        ZMW: 15,
        KES: 7401,
        RWF: 64020,
        UGX: 218343,
        GHS: 540,
        TZS: 140040,
        XAF: 37050,
        XOF: 37050,
      },
      title: "Ultimate",
      subtitle: "12 months",
    },
  ],
  {
    USD: 3,
    ZMW: 47,
    KES: 361,
    RWF: 3111,
    UGX: 11418,
    GHS: 30,
    TZS: 6996,
    XAF: 2056,
    XOF: 2056,
  },
  {
    USD: 20,
    ZMW: 380,
    KES: 2180,
    RWF: 20160,
    UGX: 70640,
    GHS: 120,
    TZS: 46640,
    XAF: 13706,
    XOF: 13706,
  },
  {
    USD: 50,
    ZMW: 950,
    KES: 5470,
    RWF: 50410,
    UGX: 176610,
    GHS: 300,
    TZS: 116600,
    XAF: 34266,
    XOF: 34266,
  },
  {
    USD: 150,
    ZMW: 1950,
    KES: 15470,
    RWF: 50410,
    UGX: 1176610,
    GHS: 1300,
    TZS: 349800,
    XAF: 102797,
    XOF: 102797,
  },
];
export const mob = [
  {
    opt: [{ code: "MPS", name: "M-Pesa" }],
    currencies: ["RWF", "TZS", "UGX", "ZMW"],
  },
  {
    opt: [{ code: "FMM", name: "FMM" }],
    currencies: ["XAF", "XOF"],
  },
  {
    opt: [
      { code: "MPS", name: "M-Pesa" },
      { code: "MPX", name: "Airtel Kenya" },
    ],
    currencies: ["KES"],
  },
  {
    opt: [
      { code: "MTN", name: "MTN" },
      { code: "TIGO", name: "TIGO" },
      { code: "VODAFONE", name: "VODAFONE" },
      { code: "AIRTEL", name: "AIRTEL" },
    ],
    currencies: ["GHS"],
  },
];
export const searchTabs = [
  { title: "Search for a job", path: "/find_job" },
  { title: "Search for a caregiver", path: "/find_childcarer" },
];

export const courseInfo = [
  {
    title: "Child Care and Safety",
    url: require("../assets/images/courses/care_planning.jpg"),
    affliateLink:
      "https://alison.com/course/child-care-health-and-safety?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqeq3sasdasddas",
    info: "Taking care of children is a demanding task but the proper knowledge and experience can make it fun. You will learn how to take care of children by keeping them safe and healthy at different stages of their development. We will explore how to fulfil children's needs, give them the right food and environment to grow, protect them against abuse and neglect and encourage play for learning and experience.",
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },
  {
    title: "Parenting and Supervion For Child Development",
    url: require("../assets/images/courses/child_care.jpg"),
    affliateLink:
      "https://alison.com/course/early-years-supervision-for-child-development?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqefedsfsq3gfsdfs",
    info: "Parents, babysitters and childcare service providers must have certain attributes and skills to do their job of cultivating healthy childhood development. This childcare course lays out the responsibilities of childcare providers and describes the common features of childcare settings. We go over policies and procedures related to childcare services to help you understand the needs of children and supervise their growth and development.",
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },
  {
    title: "Introduction to Caregiving",
    url: require("../assets/images/courses/child_care2.jpg"),
    affliateLink:
      "https://alison.com/course/child-care-health-and-safety?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqfddsfsdeq3sadasdas",
    info: "This free online Caregiver training course will give you an understanding of the basics of caregiving, including legal and ethical responsibilities and working as part of a team. Caregivers are highly valued and in-demand professionals who make a big difference to the health organizations they work for. This course will give you a full run-through of these essential concepts so that you can give your caregiving career a real boost today.",
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },
  {
    title: "Montessori Language Course (Early Childhood and Elementary)",
    url: require("../assets/images/courses/child_care3.jpg"),
    affliateLink:
      "https://alison.com/course/child-care-health-and-safety?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqedsadasq3dsfd",
    info: "Step by step video presentations and pdf manuals and resources that will empower you as a Montessori teacher",
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },
  {
    title: "Food and Nutrition",
    url: require("../assets/images/courses/child_care4.jpg"),
    affliateLink:
      "https://alison.com/course/introduction-to-food-and-nutrition?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqedasdasq3dfsdfsd",
    info: `Food and nutrition are crucial for good health and longevity. This course will teach you all about nutrients and their functions in the human body. Discover how to correctly compute macronutrients to reach your daily recommended values and understand food sources of nutrients that treat or prevent diseases. Assess various diets and research all the possibilities to equip you to make the best decisions for your health transformation.`,
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },
  {
    title: "Diabetes",
    url: require("../assets/images/18736834_m.jpg"),
    affliateLink:
      "https://alison.com/course/understanding-diabetes-management-and-balanced-diet?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqefedsfq3sdafs",
    info: "Do you want to proactively maintain a healthy lifestyle to prevent diabetes or manage your existing diabetes? Diabetes can be properly managed with the right attitude and approach. This nutrition course examines the underlying causes of diabetes, its symptoms and how to manage it. We show you how person-centred support and a balanced diet can prevent diabetes or allow you to control it. Sign up to reap the benefits of eating healthily.",
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },

  {
    title: "Nursing",
    url: require("../assets/images/42398864_m.jpg"),
    affliateLink:
      "https://alison.com/course/foundations-of-nursing?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: "wqefedsfq3sdafs",
    info: "Do you want to help sick and injured people? If so, then a career in nursing may suit you. From assessment to diagnosis, a nurse's critical labour can mean the difference between life and death. This nursing course helps you develop the necessary skills and abilities required to excel in nursing, feel fulfilled in your personal and professional life and constantly have the chance to advance both. Sign up to learn the fundamentals of nursing.",
    prices: { USD: 5, ZMW: 95, KES: 547, RWF: 5041, UGX: 17661, GHS: 30 },
  },
];

export const personalityTestsInfo = [
  {
    title: "Caregiver & Nanny Test",
    url: require("../assets/images/courses/care_planning.jpg"),
    affliateLink:
      "https://alison.com/course/child-care-health-and-safety?utm_source=alison_user&utm_medium=affiliates&utm_campaign=34990661",
    post_id: 1,
    info: "The Veritas nursing evaluation is designed to help organizations assess risks in hiring for roles that support vulnerable populations, such as children and the elderly. This evaluation focuses on key criteria like integrity, personality characteristics, emotional treatment styles, and professional orientation. Conducted online, it consists of interactive questions aimed at ensuring honest responses. The resulting report categorizes applicants into risk levels and provides detailed insights, helping employers make informed hiring decisions to ensure safety and care.",

    prices: ["ZMW: 1"],
  },
];
export const cookInfo = [
  {
    title: "Basic information",
    path: `${ACC_CAREGIVER}my_profile/basic_information_edit`,
    status: "basic_information",
  },

  {
    title: "Personal description",
    path: `${ACC_CAREGIVER}my_profile/personal_description_edit`,
    status: "personal_description",
  },
  {
    title: "Photos & Document uploads",
    path: `${ACC_CAREGIVER}my_profile/uploads_edit`,
    status: "uploads",
  },
];
export const profileInfo = [
  {
    title: "Basic information",
    path: `${ACC_CAREGIVER}my_profile/basic_information_edit`,
    status: "basic_information",
  },
  {
    title: "Roles",
    path: `${ACC_CAREGIVER}my_profile/roles_edit`,
    status: "roles",
  },
  {
    title: "Personal description",
    path: `${ACC_CAREGIVER}my_profile/personal_description_edit`,
    status: "personal_description",
  },
  {
    title: "Photos & Document uploads",
    path: `${ACC_CAREGIVER}my_profile/uploads_edit`,
    status: "uploads",
  },
];
const payInfo = `For a one-time payment of <$> for a month, unlimited applications a day. 

Other packages include <$$> for 3 months and <$$$> for 12 months. You can also pay for others`;
const refInfo = `Your network is your networth. Sharing is caring. Share our link with your network.
    
Invite a friend and get 5 percent of their first purchase.

Invite more to qualify as an Accredited Affliate and get up to 10 percent`;
export const carer_tabs = [
  {
    title: "My account",
    path: `${ACC_CAREGIVER}overview/user`,
    info: `Create or edit your profile and upload your resume.

    In the caregiver search, parents can find you.`,
  },
  {
    title: "My profile",
    path: `${ACC_CAREGIVER}my_profile/user`,
    info: `Create or edit your profile and upload your resume.

    Parents will then be able to find you in the caregiver search.`,
  },

  {
    title: "Featured caregiver",
    path: `${ACC_CAREGIVER}featured_nanny/user`,
    info: `Become a featured caregiver.

    Appear near the top of all search results.

    Get noticed by all parents.`,
  },
  {
    title: "Applications / Offers",
    path: `${ACC_CAREGIVER}apply_offers/my_applications`,
    info: `See your applications and job offers from parents.`,
  },
  {
    title: "Courses",
    path: `${ACC_CAREGIVER}courses/user`,
    info: `Find your course here.`,
  },
  {
    title: "Orders",
    path: `${ACC_CAREGIVER}orders/user`,
    info: `Find orders from your clients here.`,
  },
  {
    title: "Hub posts",
    path: `${ACC_CAREGIVER}posts/user`,
    info: `Find your posts here.`,
  },
  {
    title: "Pay",
    path: `${ACC_CAREGIVER}pay/user`,
    info: payInfo,
  },
  {
    title: "Referrals",
    path: `${ACC_CAREGIVER}referrals/user`,
    info: refInfo,
  },

  {
    title: "Settings",
    path: `${ACC_CAREGIVER}settings/user`,
    info: `Modify your account settings.`,
  },
];
export const parent_tabs = [
  {
    title: "My account",
    path: `${ACC_PARENT}overview/user`,
    info: `CaregiverAfrica has hundreds of caregive profiles for you to look through.`,
  },

  {
    title: "Jobs / Applications",
    path: `${ACC_PARENT}jobs_profiles/manage_jobs`,
    info: `Create and manage your job ad to encourage caregivers to contact you.

    You can also see who has applied for your job.`,
  },
  {
    title: "Courses",
    path: `${ACC_PARENT}courses/user`,
    info: `Find your course here.`,
  },
  {
    title: "Carts / Orders",
    path: `${ACC_PARENT}carts_orders/carts`,
    info: `Find your carts and orders here.`,
  },
  {
    title: "Hub posts",
    path: `${ACC_PARENT}posts/user`,
    info: `Find your posts here.`,
  },
  {
    title: "Pay",
    path: `${ACC_PARENT}pay/user`,
    info: payInfo,
  },

  {
    title: "Referrals",
    path: `${ACC_PARENT}referrals/user`,
    info: refInfo,
  },

  {
    title: "Settings",
    path: `${ACC_PARENT}settings/user`,
    info: `Modify your account settings.`,
  },
];
export const parent_jobs = [
  {
    title: "Create / manage jobs",
    path: `${ACC_PARENT}jobs_profiles/manage_jobs`,
  },
  {
    title: "Profile contact history",
    path: `${ACC_PARENT}jobs_profiles/contact_history`,
  },
  {
    title: "Recieved applications",
    path: `${ACC_PARENT}jobs_profiles/recieved_applications`,
  },
];
export const caregiver_jobs = [
  {
    title: "Job offers",
    path: `${ACC_CAREGIVER}apply_offers/recieved_job_offers`,
  },
  {
    title: "My applications",
    path: `${ACC_CAREGIVER}apply_offers/my_applications`,
  },
];
export const carts_orders = [
  {
    title: "Carts",
    path: `${ACC_PARENT}carts_orders/carts`,
  },
  {
    title: "Orders",
    path: `${ACC_PARENT}carts_orders/orders`,
  },
];
export const headerPayloads = {
  overview_childcarer: {
    label: "My account",
    sub: `Search jobs to find your ideal position.
    Please keep your profile up to date as it will be used in job applications.`,
    btnLabel: "Be a featured caregiver",
    path: `${ACC_CAREGIVER}featured_nanny/user`,
  },
  roles_edit: {
    label: "My profile roles",
    sub: `Please indicate the type of job you are seeking.`,
  },
  overview_parent: {
    label: "My account",
    sub: `Find caregivers and post your job openings.`,
    btnLabel: "Submit a job",
    path: "/createjob",
  },
  locations_edit: {
    label: "My profile locations",
  },
  featured_nanny: {
    label: "Become a featured caregiver",
    sub: "All of our parents will see you.",
  },
  my_applications: {
    label: "Applications",
    sub: "Previous job applications",
  },
  recieved_applications: {
    label: "Recieved Applications",
    sub: "View the job applications you've received.",
  },
  contact_history: {
    label: "Profile contact history",
    sub: `Caregivers you've contacted through their profiles`,
  },
  pay: {
    label: "Pay for full access",
    sub: `For a one-time payment of <$> for a month, <po$t>. 
Other packages include <$$> for 3 months and <$$$> for 12 months.`,
  },
  referrals: {
    label: "Earn cool cash",
    sub: `Earn some passive income by sharing our link with your network`,
    btnLabel: "Share Link",
    path: "Share_Link",
  },
  settings: {
    label: "Account settings",
    sub: "Select your settings",
  },
  my_profile: {
    label: "My profile",
  },
  basic_information_edit: {
    label: "My profile",
  },
  personal_description_edit: {
    label: "My profile",
  },
  courses: {
    label: "My courses",
    sub: "Get ahead of the game with update courses",
  },
  orders: {
    label: "Orders",
  },
  carts: {
    label: "Carts",
  },
  posts: {
    label: "posts",
    sub: "See all your hub posts",
  },

  recieved_job_offers: {
    label: "Recieved job offers",
  },
  manage_jobs: {
    label: "Create / Manage jobs",
  },

  medical_test: {
    label: "medical test",
  },
};

export const newCart = {
  name: "create cart",
  date: new Date(),
  items: 0,
  total: 0,
  post_id: "123",
};
export const regionsOpt = [
  { name: "Lusaka", cities: ["Lusaka"] },
  { name: "Cooperbelt", cities: ["Kitwe", "Ndola"] },
  { name: "Southern", cities: ["Livingston", "Choma", "Chirundu"] },
  { name: "North-western", cities: ["Solwezi"] },
  { name: "Central", cities: ["Kabwe"] },
  { name: "Western", cities: ["Mongu"] },
  { name: "Northern", cities: ["Nakonde"] },
  { name: "Muchinga", cities: ["Mpika"] },
  { name: "Luapula", cities: ["Mansa"] },
  { name: "Eastern", cities: ["Chipata"] },
];
export const areasOpt = regionsOpt.reduce((r, obj) => r.concat(obj.cities), []);
export const regionalOpt = regionsOpt.map((position) => position.name);
export const user_types = [
  { value: "childcarer", label: "caregiver" },
  { value: "cook", label: "cook" },
  { value: "parent", label: "parent" },
];
export const policy = `Caregiverafrica requires access to your location to better serve the user with relevant info and job postings. The user loaction is only used for this sole purpose and will never be shared with any third parties. 

Other permisions to access camera and file storage enable the user to upload a profile photo and other documents which a public facing.
   
   
Jobs are posted by parents who want to hire a nanny directly.

Parents can use the site to conduct free searches. You only pay if you want to contact a Caregiver.


The site is not a Recruitment Agency. Before meeting or engaging with those they find on the website, childcarers and parents should always conduct the necessary checks.

We do not provide Caregiver advice because we are not Caregiver professionals.

Please send us an email if you have any feedback or suggestions.

Finally, if you find a job or hire a Caregiver as a result of a contact made through this site, we would love to hear from you.`;
export const emailObj = {
  name: "email",
  err: "email required",
  type: "email",
  val: "",
};
export const passwordObj = {
  name: "password",
  err: "password required",
  type: "password",
  val: "",
};
export const entry_form = {
  register_as: {
    body: "",
    title: "Register As",
    action: "Register",
    data: [
      {
        name: "user_type",
        type: "select",
        payload: user_types,
        val: "childcarer",
      },
      {
        name: "terms",
        err: "must agree to the terms and conditions to register",
        type: "checkbox",
        header: "Terms & Conditions",
        policy,
        label:
          "I agree to receive emails from CaregiverAfrica, and I have read and accepted the",
        span: "terms and conditions",
        val: false,
      },
    ],
  },
  assign_admin: {
    title: "User Roles",
    body: "Enter user email",
    action: "Apply",
    data: [
      emailObj,
      {
        name: "super_admin",
        type: "checkbox",

        label: "Super Admin",
        val: false,
      },
      {
        name: "admin",
        type: "checkbox",

        label: "Admin",
        val: false,
      },
      {
        name: "accredited",
        type: "checkbox",

        label: "Accredited Affliate",
        val: false,
      },
    ],
  },
  email_password: {
    data: [emailObj, passwordObj],
  },
};

export const regAs = {
  title: "Find a Caregiver job that suits you.",
  subtitle: "There are 1436 jobs available on nannyjob today",
  btn: "Register",
  path: "/register",

  steps: [
    "Jobs obtained directly from parents",
    "Search jobs and get jobs emailed to you every day",
    "You will undoubtedly find your ideal position as a nanny, Au Pair, or other caregiver jobs.",
  ],
};
export const docs = [
  {
    label: "Upload photo",
    path: "photoUrl",
    mime: "images/*",
    info: `Choose the photo you would like associated with your profile
(optional).`,
  },
  {
    label: "Upload cv",
    path: "docUrl",
    mime: "application/pdf",
    info: `This will be the primary description for your profile. Please include any additional information about yourself, your skills, your hobbies, or anything else that nanny agencies or parents may find useful.

  Please do not mention your race, ethnic origin, politics, religion, trade union membership, health, sex life, or sexual orientation for data protection reasons.`,
  },
  {
    label: "Upload police clearance",
    path: "policeUrl",
    mime: "application/pdf",
    info: `Upload your police clearance document to show that you have no criminal record
(optional).`,
  },
  {
    label: "Upload Medical clearance",
    path: "medUrl",
    mime: "application/pdf",
    info: `Upload your medical clearance document to make parents aware of the medical conditions you might have if any
(optional).`,
  },

  {
    label: "Upload Accedimic certificate",
    path: "certificateUrl",
    mime: "application/pdf",
    info: `Upload any accedimic qualification to inform parents about your education status
(optional).`,
  },
];
let date = new Date();
date.setDate(date.getDate() - 1);
export const user = {
  info: "",
  username: "",
  surname: "",
  fullname: "",
  address: "",
  postcode: "",
  sex: "",
  smoker: "Not specified",
  email: "",
  phone: "",
  user_id: "",
  driver: "Not specified",
  car: "Not specified",
  dob: date,
  signup_compelete: false,
  verified: false,
  pending: true,
  qualifications: "",
  photoUrl: null,
  docUrl: null,
  policeUrl: null,
  medUrl: null,
  certificateUrl: null,
  currently_in: "Select country",
  experience: "Not specified",
  languages: ["Not specified", "Not specified", "Not specified"],
  origin: "Select nationality",
  positions: ["Any Position"],
  region: "",
  town: "",
  live_in_out: "Either",
  start_date: date,
  salary: "Competitive",
  full_part_time: "Either",
  duration: "Less than 6 months",
  work_type: "Either",
  Ratings: -1,
  date_created: date,
  jobs: date,
  featured: date,
  notes: date,
  date_updated: date,
  user_type: "",
  live: true,
  isCompelete: false,
  send_emails: true,
  status: {
    basic_information: false,
    roles: false,
    personal_description: false,
    uploads: false,
  },
  pets: "Not specified",
  cpr: "Not specified",
  education: "Not specified",
  number_kids: "Not specified",
  age_range: "Not specified",
};

export const filters = [
  {
    title: "Sex",
    name: "sex",

    info: ["Either", ...sexOpt.slice(1)],
  },
  {
    name: "experience",
    title: "Experience",

    info: experienceOpt,
  },

  {
    title: "Education level",
    name: "education",

    info: ["Any Level", ...educationOpt.slice(1)],
  },
  {
    title: "Number of kids to care for",
    name: "number_kids",
    info: experienceOpt,
  },
  {
    title: "Age range for kid(s)",
    name: "age_range",
    info: rangeOpt,
  },
  {
    title: "Comfortable with pets",
    name: "pets",

    info: selOpt,
  },
  {
    title: "CPR / First Aid trained",
    name: "cpr",

    info: selOpt,
  },
  {
    title: "Smoker",
    name: "smoker",

    info: selOpt,
  },
  {
    name: "driver",
    title: "Driving licence",

    info: selOpt,
  },
  {
    title: "Full or part time",
    name: "full_part_time",

    info: ["Both", ...workOpt.slice(1)],
  },

  {
    title: "Temporary or permanent",
    name: "temp_permanent",

    info: ["Both", ...termOpt.slice(1)],
  },

  {
    title: "Live in or live out",
    name: "live_in_out",

    info: ["Both", ...liveOpt.slice(1)],
  },
  {
    title: "Language",
    name: "language",

    info: ["Any Language", ...languagesOpt.slice(1)],
  },
];
export const job_filters = [
  {
    title: "Sex",
    name: "sex",

    info: ["Either", ...sexOpt.slice(1)],
  },
  {
    title: "Driver",
    name: "driver",

    info: ["Either", ...selOpt.slice(1)],
  },
  {
    title: "Owns car",
    name: "car",

    info: ["Either", ...selOpt.slice(1)],
  },
  {
    title: "Comfortable with pets",
    name: "pets",

    info: ["Either", ...selOpt.slice(1)],
  },
  {
    title: "CPR / First Aid trained",
    name: "cpr",

    info: ["Either", ...selOpt.slice(1)],
  },
  {
    title: "Education level",
    name: "education",

    info: ["Any Level", ...educationOpt.slice(1)],
  },
  {
    title: "Smoker",
    name: "smoker",

    info: ["Either", ...selOpt.slice(1)],
  },
  {
    title: "Full or part time",
    name: "full_part_time",

    info: workOpt,
  },

  {
    title: "Temporary or permanent",
    name: "work_type",

    info: termOpt,
  },
  {
    title: "Work period",
    name: "duration",

    info: ["Not specified", ...durationOpt],
  },
  /* {
	title: "How soon do you need someone",
	name: "start_date",

	info: starterOpt,
  }, */

  {
    title: "Live in or live out",
    name: "live_in_out",

    info: liveOpt,
  },

  {
    title: "Language",
    name: "languages",

    info: ["Any Language", ...languagesOpt.slice(1)],
  },
  {
    title: "Childcare experience",
    name: "experience",

    info: experienceOpt,
  },
  {
    title: "Number of kids to care for",
    name: "number_kids",

    info: experienceOpt,
  },
  {
    title: "Age range for kid(s)",
    name: "age_range",

    info: rangeOpt,
  },
];
export const about_pay = [
  [
    ["Create a job advertisement", "Search for and contact caregivers", ""],
    [
      "Create a job advertisement",
      "Search for and contact caregivers",
      "Personality Test for 2 Candidates",
    ],
    [
      "Create a job advertisement",
      "Search for and contact caregivers",
      "Personality Test for 2 Candidates",
    ],
  ],
  [
    ["Search for and apply", `Increase your job`, ""],
    [
      "Search for and apply",
      `Increase your job`,
      "Personality Test for 2 Candidates",
    ],
    [
      "Search for and apply",
      `Increase your job`,
      "Personality Test for 2 Candidates",
    ],
  ],
];
export const actions = [
  {
    title: "Full report",
    doc: "users/total",

    tag: "All Registered Users",
    path: "allUsers",
  },
  {
    title: "Full report",
    doc: "pending_orders/total",

    tag: "Total pending orders",
    path: "orders",
  },
  {
    title: "Full report",
    doc: "pending_cook/total",

    tag: "Total unverified cooks",
    path: "users",
  },
  {
    title: "Full report",
    doc: "active_users/total",

    tag: "Total caregivers",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "active_parents/total",

    tag: "Total parents",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "paid_parents/total",

    tag: "Total paid up parents",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "featured_caregivers/total",

    tag: "Total featured caregivers",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "paid_courses",
    query: "day",
    tag: "Total paid courses",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "incompelete_users/total",

    tag: "Total incompelete users",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "incompelete_checkouts/total",

    tag: "Total abandoned check outs",
    path: "reports",
  },
  {
    title: "view profile",
    doc: "most_viewed_caregiver/total",

    tag: "Most viewed caregiver",
    path: "cv",
  },
  {
    title: "view job",
    doc: "most_viewed_job/total",

    tag: "Most viewed job",
    path: "job",
  },
  {
    title: "Full report",
    doc: "traffic/total",

    tag: "Top traffic source",
    path: "reports",
  },
  {
    title: "Full report",
    doc: "caregiver_reports",

    tag: "Run caregiver reports",
    path: "reports",
  },
];
export const traffic = [
  "Facebook",
  "Twiter",
  "Linkedin",
  "Google",
  "From my friend",
  "Other",
];

export const reg_acc = [
  {
    user_type: "childcarer",
    title: "Register as a caregiver for free",
    body: `Register for free to find your dream job.
    
  For a one-time payment of <$> for a month, unlimited applications a day. 
  Other packages include <$$> for 3 months and <$$$> for 12 months`,
  },
  {
    user_type: "cook",
    title: "Register as a cook for free",
    body: `Register for free to prepare nutrious meals for patients.
    
    For a one-time payment of <$> for a month, unlimited applications a day. 
    Other packages include <$$> for 3 months and <$$$> for 12 months`,
  },
  {
    user_type: "parent",
    title: "Register as a parent for free",
    body: `Search for free.

    For a one-time payment of <$> for a month, post a job and contact caregivers. 
    Other packages include <$$> for 3 months and <$$$> for 12 months (one-off payment, no extra costs or ongoing subscription).`,
  },
];
export const post = { photoUrl: null, title: "", info: "", file: null };
