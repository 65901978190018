import { useRef,memo,lazy,Suspense } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { DARKBLUE, GREEN, LIGHTBLUE } from '../constants/Colors';
import { RWebShare } from "react-web-share";
import { URL_STR } from '../constants/AppData';


const CustomBtn =lazy(()=>import( './CustomBtn'));
const ReportFilters =lazy(()=>import( './ReportFilters'));
const CartSel =lazy(()=>import( './CartSel'));

const Div = styled.div`
  width: 100%;
  border: rgb(229, 230, 229) 1px solid;
  border-radius: 40px;
  margin: 17px 0px;
  padding: 30px 40px;
  h3 {
    color: ${DARKBLUE};
  }
  p {
    color: ${GREEN};
    white-space: pre-line;
  }
`;

function Header({
  label,
  sub,
  btnLabel,
  searching,
  path,
  btnClicked,
  addClicked,
  editClicked,
  filters,
  carts,
  sel,
  onInputChange,
  user_id
}) {
  const ref = useRef();
  
  return (
    <Div>
      <Row>
        <Col>
          <h3>{label}</h3>
          {sub && <p>{sub}</p>}
          <Suspense fallback={<Spinner/>}>
          {filters && (
            <ReportFilters
              filterChange={(payload) => {
                console.log('ref.current ', payload);
                ref.current = payload;
              }}
            />
          )}
          {carts && (
            <CartSel
              carts={carts}
              sel={sel}
              addClicked={addClicked}
              editClicked={editClicked}
              onInputChange={onInputChange}
            />
          )}
          </Suspense>
        </Col>

        {btnLabel && (
          <Col
            md="auto"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Suspense fallback={<Spinner/>}>
              {path==='Share_Link'?<RWebShare
              data={{
                text: "Where careseekers meet with caregivers.",
                url:URL_STR+'dlink/?link='+URL_STR+'?id='+user_id+'&st=Join+me+on+CaregiverAfrica&sd=Where+careseekers+meet+with+caregivers.&si=https://firebasestorage.googleapis.com/v0/b/caregiverafrica.appspot.com/o/logo.png?alt%3Dmedia%26token%3Daf77c494-4fed-4394-87f6-fc80bc7e694c',
                title: "Join on CaregiverAfrica",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className='button_1'>Share 🔗</button>
            </RWebShare>
              :<CustomBtn
                label={btnLabel}
                type="button"
                uploading={searching}
                disabled={searching}
                color1={!searching ? GREEN : '#c84b1565'}
                color2={!searching ? LIGHTBLUE : '#c84b1565'}
                btnClicked={() => btnClicked(filters ? ref.current : path)}
                margin="0px"
              />
            }
            </Suspense>
          </Col>
        )}

      </Row>
    </Div>
  );
}

export default memo(Header);
