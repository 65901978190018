import {firebase} from '../config/fbConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(firebase);
export const verifyTxn = (data, type, verifyCallback) => async () => {
  console.log('emailed verifyAcc ');

  httpsCallable(
    functions,
    type
  )(data).then((response) => {
    if (verifyCallback) verifyCallback(response);
  });
};
