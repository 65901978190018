import { createStore, applyMiddleware, compose } from 'redux';
import combineReducers from '../reducers';
import thunk from 'redux-thunk';
import {firebase} from '../config/fbConfig';
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage: storage,
  whiteList: ['cla_datastore', 'cla_menuReducer', 'cla_authReducer'],
};
let middleware = [thunk.withExtraArgument(firebase)];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, createLogger()];
} else {
  middleware = [...middleware, thunk];
}
const enHanceCreateStore = compose(applyMiddleware(...middleware))(
  createStore
);
const persistedReducer = persistReducer(persistConfig, combineReducers);

export const store = enHanceCreateStore(persistedReducer);
export const persistedStore = persistStore(store);
