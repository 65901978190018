import React, { Component } from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import { Link } from 'react-router-dom';
import NavIcons from './NavIcons';
import LogoSVG from '../logo/logoComp';

const Nav = styled.nav`
	width: 100%;
	height: 85px;
	background-color: white;
	color: black;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;

	.top-nav {
		display: flex;
		justify-content: space-between;
	}
	.bottom-nav {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.top-icons {
		display: none;
	}
	.bottom-icons {
		display: flex;
		justify-content: flex-end;
	}
	@media (max-width: 1199px) {
		flex-flow: column nowrap;
		height: 115px;
		.top-icons {
			display: flex;
			justify-content: flex-end;
		}
		.bottom-icons {
			display: none;
		}
	}
	@media (max-width: 1007px) {
		height: 55px;
		.top-icons {
			margin-right: 2.1rem;
		}
	}
`;

class Navbar extends Component {
	render() {
		return (
			<Nav>
				<div className='top-nav'>
					<Link to='/'>
						<LogoSVG className='logo' width={67} />
					</Link>
					<div className='top-icons'>
						<NavIcons />
					</div>
				</div>
				<div className='bottom-nav'>
					<div className='bottom-icons'>
						<NavIcons />
					</div>
					<Burger />
				</div>
			</Nav>
		);
	}
}

export default Navbar;
