import React from "react";
import { CDBCard, CDBCardBody } from "cdbreact";

const cardStyle = {
  margin: "10px",
  padding: "20px",
  borderRadius: "15px",
  width: "100%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const headerStyle = {
  marginBottom: "15px",
  fontWeight: "bold",
};
const contentStyle = {
  display: "flex",
   justifyContent: "space-between", 
  alignItems: "center",
};

const getStylesByTransactionType = (transactionType) => {
  switch (transactionType) {
    case "Success":
      return {
        headerColor: "green",
        totalColor: "green",
      };
    case "Failed":
      return {
        headerColor: "red",
        totalColor: "red",
      };
    case "Pending":
      return {
        headerColor: "orange",
        totalColor: "orange",
      };
    default:
      return {
        headerColor: "black",
        totalColor: "black",
      };
  }
};

const TotalTransactionsCard = ({ total, transactionType }) => {
  const { headerColor, totalColor } =
    getStylesByTransactionType(transactionType);

  // Define header and total styles
  const headerStyle = {
    marginBottom: "15px",
    fontWeight: "bold",
    color: headerColor,
  };

  const totalStyle = {
    color: totalColor,
    //    marginTop: '5px',
    marginLeft: "10px",
  };
  return (
    <CDBCard style={cardStyle} className="total-transactions-card">
      <CDBCardBody>
        <div style={contentStyle}>
          <h5 style={headerStyle} className="card-header">
            {transactionType}
          </h5>
          <h3 style={totalStyle}>{total}</h3>
        </div>
      </CDBCardBody>
    </CDBCard>
  );
};

export default TotalTransactionsCard;
