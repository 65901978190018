//import action-types
import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNOUT_SUCCESS,
  SIGNOUT_ERROR,
  DISABLE,
  CLEAR,
  STORE_ID,
  CURRENT_USER,
  VER,
  CURRENT_ADMIN,
} from "../constants";
//initialize state
const INITIAL_STATE = {
  authMsg: "",
  uid: "",
  current: null,
  admin: {
    username: "",
    super_admin: false,
    admin: false,
    isEmpty: false,
    loggedin: false,
  },
  verificationId: null,
  disable: false,
};
//reducer
const authReducer = function (state = INITIAL_STATE, action) {
  //write conditional to handle action-response
  if (
    action.type === SIGNIN_SUCCESS ||
    action.type === SIGNOUT_SUCCESS ||
    action.type === SIGNUP_SUCCESS ||
    action.type === SIGNUP_ERROR ||
    action.type === SIGNIN_ERROR ||
    action.type === SIGNOUT_ERROR
  ) {
    console.log("sign up successful ", action.payload);

    return { ...state, authMsg: action.payload };
  } else if (action.type === DISABLE) {
    console.log("disabled ", action.payload);
    return { ...state, disable: action.payload };
  } else if (action.type === STORE_ID) {
    console.log("id ", action.payload);
    return { ...state, uid: action.payload };
  } else if (action.type === CLEAR) {
    console.log("clear authMsg ", action.payload);

    return { ...state, authMsg: "", uid: "", current: null };
  } else if (action.type === VER) {
    console.log("clear authMsg ", action.payload);

    return { ...state, verificationId: action.payload };
  } else if (action.type === CURRENT_USER) {
    console.log("current authMsg ", action.payload);

    return { ...state, current: action.payload };
  } else if (action.type === CURRENT_ADMIN) {
    console.log("current authMsg ", action.payload);

    return { ...state, admin: action.payload };
  } else {
    return state;
  }
};
export default authReducer;
