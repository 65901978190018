import React from 'react';
import profile from '../../assets/images/profile.png';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GREEN } from '../../constants/Colors';

import { useSelector } from 'react-redux';
//import { GiHotMeal } from 'react-icons/gi';
import { GrUserAdmin } from 'react-icons/gr';

function NavIcons() {
	const { photoUrl, user_type, user_id } = useSelector(
		({ cla_datastore: { user } }) => user || {},
	);
	const isAdmin = useSelector(
		({ cla_authReducer: { current } }) => current?.admin,
	);

	const history = useHistory();
	const location = history.location;
	const path = location.pathname;
	const subPath = `${path.substring(path.lastIndexOf('/') + 1, path.length)}${
		location.search ? location.search : ''
	}`;
	console.log('path ', path);
	const param = path.includes('my_account')
		? subPath
		: user_id
		? user_id
		: 'user_id';
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{/* <GiHotMeal
        color={GREEN}
        style={{ margin: '0px 5px', cursor: 'pointer' }}
        size="1.7em"
        onClick={() => history.push('/menu')}
      /> */}

			{isAdmin && (
				<GrUserAdmin
					color={GREEN}
					style={{ margin: '0px 5px', cursor: 'pointer' }}
					size='1.7em'
					onClick={() => history.push('/admin')}
				/>
			)}
			<div
				style={{
					backgroundColor: 'white',
					borderRadius: '50%',
					padding: 1,
					cursor: 'pointer',
				}}
				onClick={() => {
					const path = `/my_account/${
						user_type === 'parent' ? 'parent' : 'caregiver'
					}/overview/user/${param}`;
					console.log('acc path ', path);
					history.push(path);
				}}
			>
				<Image
					alt='profile pic'
					src={photoUrl || profile}
					height={40}
					width={40}
					roundedCircle
				/>
			</div>
		</div>
	);
}

export default NavIcons;
