import React, { useState, useEffect } from "react";
import firestore from "../../config/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from "cdbreact";
import Wrapper from "../../wrapper/index";
import Header from "../../components/Header";
import TotalTransactionsCard from "../../components/TotalTransactionsCard";

const GetAllTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);


  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp.seconds * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactionscollection = collection(firestore, "transactions");
        const querySnapshot = await getDocs(transactionscollection);

        const fetchedTransactions = [];
        let counter = 1;
        querySnapshot.forEach((doc) => {
          fetchedTransactions.push({
            _id: counter++,
            amount: doc.data().amount,
            description: doc.data().description,
            email: doc.data().email,
            paymentType: doc.data().payment_type,
            startDate: doc.data().start_date,
            status: doc.data().status,
            transactionId: doc.data().transaction_id,
            start_date: formatTimestamp(doc.data().date)
            // date_created: doc.data().date_created.toDate().toString(),
          });
        });

        setTransactions(fetchedTransactions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const countTransactionsByStatus = (status) => {
    return transactions.filter((transaction) => transaction.status === status)
      .length;
  };

  const totalTransactions = transactions.length;
  const totalFailedTransactions = countTransactionsByStatus("FAILED");
  const totalSuccessfulTransactions = countTransactionsByStatus("SUCCESS");
  const totalPendingTransactions = countTransactionsByStatus("PENDING");
  const totalTransactionsHeading = "Total";
  const totalSuccessTransactionsHeading = "Success";
  const totalPendingTransactionsHeading = "Pending";
  const totalFailedTransactionsHeading = "Failed";

  return (
    <Wrapper>
      <Header label="Total Transactions" />

      <CDBContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px", // Optional: Add space between the cards
            // flexWrap: "wrap", // Allow cards to wrap to the next line if necessary
          }}
        >
          <TotalTransactionsCard
            total={totalTransactions}
            transactionType={totalTransactionsHeading}
          />
          <TotalTransactionsCard
            total={totalSuccessfulTransactions}
            transactionType={totalSuccessTransactionsHeading}
          />
          <TotalTransactionsCard
            total={totalPendingTransactions}
            transactionType={totalPendingTransactionsHeading}
          />
          <TotalTransactionsCard
            total={totalFailedTransactions}
            transactionType={totalFailedTransactionsHeading}
          />
        </div>
        <br/>
        <CDBCard>
          <CDBCardBody>
            <CDBDataTable
              bordered
              hover
              striped
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              itemsPerPageSelect
              itemsPerPage={5}
              materialSearch={true}
              data={{
                columns: [
                  { label: "No", field: "_id" },
                  { label: "Amount", field: "amount" },
                  { label: "Description", field: "description" },
                  { label: "Payment Type", field: "paymentType" },
                  { label: "Status", field: "status" },
                  { label: "Transaction ID", field: "transactionId" },
                  {label: "Start Date", field: "start_date"}
                ],
                rows: transactions,
              }}
            />
          </CDBCardBody>
        </CDBCard>
      </CDBContainer>
    </Wrapper>
  );
};

export default GetAllTransactions;
