import React, { useState, useEffect } from 'react';
import firestore from '../../config/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { CDBCard, CDBCardBody, CDBContainer, CDBDataTable } from 'cdbreact';
import Wrapper from '../../wrapper/index';
import Header from '../../components/Header';

const GetAllUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersCollection = collection(firestore, 'users');
                const querySnapshot = await getDocs(usersCollection);

                const fetchedUsers = [];
                let counter = 1; 
                querySnapshot.forEach(doc => {
                    fetchedUsers.push({
                        _id: counter++, 
                        name: doc.data().fullname,
                        username: doc.data().username,
                        email: doc.data().email,
                        profileStatus: doc.data().profileStatus,
                        phone: doc.data().phone,
                        // date_created: doc.data().date_created.toDate().toString(),
                    });
                });

                setUsers(fetchedUsers);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching users:', error);
                setLoading(false); 
            }
        };

        fetchData();
    }, []);

    return (
        <Wrapper>
      <Header label="Users" />

        <CDBContainer>
            <CDBCard>
                <CDBCardBody>
                    <CDBDataTable
                        bordered
                        hover
                        striped
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        itemsPerPageSelect
                        itemsPerPage={5}
                        materialSearch={true}
                        data={{
                            columns: [
                                { label: 'No', field: '_id' },
                                { label: 'Date', field: 'date_created' },
                                { label: 'Full Name', field: 'name' },
                                { label: 'Username', field: 'username' },
                                { label: 'Email', field: 'email' },
                                { label: 'Profile Status', field: 'profileStatus' },
                                { label: 'Phone', field: 'phone' },
                            ],
                            rows: users,
                            
                        }}
                    />
                </CDBCardBody>
            </CDBCard>
        </CDBContainer>
        </Wrapper>
    );
};

export default GetAllUsers;
