import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../components/Footer'
import Navbar from '../components/Nav/Navbar'

function Wrapper({children}) {
  console.log('children ',children)
    return (
        <Container>
        <Navbar />
        
          {children} 
          <hr style={{color:'#ec962f'}}/>

        <Footer/>
        </Container>
    )
}

export default Wrapper
