import { memo, useRef, useState, useEffect } from 'react';

import {
	Modal,
	Button,
	Container,
	Row,
	FloatingLabel,
	Form,
	InputGroup,
	Spinner,
	Col,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { isEmpty, startCase } from 'lodash';
import { FaEye, FaEyeSlash, FaArrowLeft } from 'react-icons/fa';

function InputModal({
	payload: {
		title,
		body,
		schema,
		data,
		object,
		initData = {},
		action,
		isLogging,
	},
	show,
	onHide,
	onDelFun,
	onActionFun,
	phone_code,
}) {
	const formRef = useRef();
	const [showPassword, setShow] = useState(false);
	const [info, setinfo] = useState();
	const { header, content, page } = info || {};
	useEffect(() => {
		setinfo({ header: title, content: body, page: 0 });
	}, [title, body]);

	return (
		<Modal
			show={show}
			onHide={onHide}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					{
						<Row>
							{page > 0 && (
								<Col>
									<FaArrowLeft
										onClick={() =>
											setinfo({ header: title, content: body, page: 0 })
										}
									/>
								</Col>
							)}
							<Col>
								<h3>{header}</h3>
							</Col>
						</Row>
					}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						{header !== 'Terms and Conditions' ? (
							<h6>{content}</h6>
						) : (
							<p style={{ whiteSpace: 'pre-line' }}>{content}</p>
						)}
					</Row>
					{data && (
						<Formik
							initialValues={initData}
							validationSchema={object(schema)}
							onSubmit={onActionFun}
							innerRef={formRef}
						>
							{({
								handleSubmit,
								errors,
								values,
								handleChange,
								touched,
								getFieldProps,
							}) => (
								<>
									<Form
										noValidate
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit(e);
										}}
									>
										{data.map((entry, idx) => (
											<div key={entry.name}>
												<>{console.log('entry ', values)}</>
												{entry &&
													(entry.type === 'password' ||
														entry.type === 'phone' ||
														entry.type === 'number' ||
														entry.type === 'email' ||
														entry.type === 'text') && (
														<Form.Group
															controlId={`formBasic${entry.name}`}
															className='is-invalid'
														>
															<InputGroup className='mb-3'>
																{entry.type === 'phone' && (
																	<InputGroup.Text>
																		{phone_code}
																	</InputGroup.Text>
																)}
																<FloatingLabel
																	label={startCase(entry.label || entry.name)}
																>
																	<Form.Control
																		type={
																			showPassword || entry.name !== 'password'
																				? 'text'
																				: 'password'
																		}
																		placeholder={startCase(
																			entry.hint || entry.name,
																		)}
																		name={entry.name}
																		{...getFieldProps(entry.name)}
																		isValid={
																			touched[entry.name] && !errors[entry.name]
																		}
																		isInvalid={
																			touched[entry.name] &&
																			!!errors[entry.name]
																		}
																	/>
																</FloatingLabel>

																{entry.appex && (
																	<>
																		{entry.appex === 'password' ? (
																			<Button
																				onClick={() => setShow(!show)}
																				variant='outline-secondary'
																			>
																				{show ? <FaEyeSlash /> : <FaEye />}
																			</Button>
																		) : (
																			<InputGroup.Text>
																				{entry.appex}
																			</InputGroup.Text>
																		)}
																	</>
																)}
																<Form.Control.Feedback type='invalid'>
																	{errors[entry.name]}
																</Form.Control.Feedback>
															</InputGroup>
														</Form.Group>
													)}
												{entry.type === 'select' && (
													<Form.Group controlId='formBasicReg'>
														<FloatingLabel
															label={startCase(entry.label || entry.name)}
														>
															<Form.Select
																name={entry.label || entry.name}
																{...getFieldProps(entry.label || entry.name)}
															>
																{entry.payload.map(({ label, value }) => (
																	<option key={label} value={value}>
																		{label}
																	</option>
																))}
															</Form.Select>
														</FloatingLabel>
													</Form.Group>
												)}

												{entry.type === 'checkbox' && (
													<>
														{entry.header && <h6>{entry.header}</h6>}
														<Form.Check type='checkbox' id={`check-api`}>
															<Form.Check
																required={entry.err}
																name={entry.name}
																checked={values[entry.name]}
																onChange={handleChange}
																isInvalid={
																	touched[entry.name] && !!errors[entry.name]
																}
															/>
															<Form.Check.Label>
																{entry.label}{' '}
																{entry.span && (
																	<span
																		onClick={() =>
																			setinfo({
																				header: entry.header,
																				content: entry.policy,
																				page: 1,
																			})
																		}
																		style={{ color: 'blue', cursor: 'pointer' }}
																	>
																		{entry.span}
																	</span>
																)}
															</Form.Check.Label>
															<Form.Control.Feedback type='invalid'>
																{errors[entry.name]}
															</Form.Control.Feedback>
														</Form.Check>
													</>
												)}
											</div>
										))}
									</Form>
								</>
							)}
						</Formik>
					)}
					{/* {radios&&radios.map(({label,value}, idx) => (<Suspense fallback={<Spinner/>}>

            <Form.Check
              type="radio"
              label={label}
              name="formHorizontalRadios"
              value={value}
              checked={radioValue === value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
              id={`formHorizontalRadios${idx}`}
            />
          </Suspense>
        ))}
          {showInput && (<Suspense fallback={<Spinner/>}>
            <FormGroup
              as={Row}
              controlId="formBasicName"
              className="is-invalid"
            >
              <Col>
                <FormLabel>{hint}</FormLabel>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8}>
                <FormControl
                  type="text"
                  {...(type === 'Message' && { as: 'textarea' })}
                  rows={type === 'Message' ? 3 : 1}
                  placeholder={hint}
                  {...((type === 'amount' || val1) && {
                    defaultValue:
                      type !== 'amount'
                        ? val1
                        : val1?.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          }),
                  })}
                  {...(type === 'amount' && {
                    value:
                      type !== 'amount'
                        ? val1
                        : val1?.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          }),
                  })}
                  onChange={(e) => {
                    console.log('emailed ', e.target.value);
                    setInput(e.target.value);
                  }}
                />
              </Col>

              <p
                style={{
                  display:
                    clicked &&
                    type === 'amount' &&
                    !isNumeric(input.replace(',', ''))
                      ? 'flex'
                      : 'none',
                  color: 'red',
                }}
              >
                {type} required
              </p>
            </FormGroup>
            </Suspense>)}
          <br />
          {showInput1 && (<Suspense fallback={<Spinner/>}>
            <FormGroup
              as={Row}
              controlId="formBasicName"
              className="is-invalid"
            >
              <Col>
                <FormLabel>password</FormLabel>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8}>
                <InputGroup className="mb-3">
                  <FormControl
                    type={show ? 'text' : 'password'}
                    placeholder="Password"
                    name="password"
                    defaultValue={input1}
                    onChange={(e) => {
                      console.log('emailed ', e.target.value);
                      setInput1(e.target.value);
                    }}
                  />

                  <InputGroup.Append>
                    <Button
                      onClick={() => setShow(!show)}
                      variant="outline-secondary"
                    >
                                        <Suspense fallback={<Spinner/>}>

                      {show ? <FaEyeSlash /> : <FaEye />}
                      </Suspense>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

                <p
                  style={{
                    display:
                      clicked && (input1 === null || input1 === '')
                        ? 'flex'
                        : 'none',
                    color: 'red',
                  }}
                >
                  password required
                </p>
              </Col>

              <p
                style={{
                  display:
                    clicked &&
                    type === 'amount' &&
                    !isNumeric(input.replace(',', ''))
                      ? 'flex'
                      : 'none',
                  color: 'red',
                }}
              >
                {type} required
              </p>
            </FormGroup>
            </Suspense>)}
          {showCheckInput && (<Suspense fallback={<Spinner/>}>
            <Col style={{ marginTop: 7 }}>
              <FormGroup controlId="formBasicCheckbox">
                <FormCheck
                  type="checkbox"
                  label="  Super Admin"
                  onChange={(e) => {
                    console.log('emailed checked ', e.target.checked);

                    setClickedSuper(e.target.checked);
                  }}
                />
              </FormGroup>

              <FormGroup controlId="formBasicCheckbox">
                <FormCheck
                  type="checkbox"
                  label=" Admin"
                  onChange={(e) => {
                    console.log('emailed checked ', e.target.checked);

                    setClickedAdmin(e.target.checked);
                  }}
                />
              </FormGroup>
             
            </Col>
            </Suspense>)} */}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<div style={{ flex: 1, justifyContent: 'space-evenly' }}>
					{onDelFun && (
						<Button
							variant='danger'
							style={{ margin: 7 }}
							onClick={(e) => {
								e.stopPropagation();

								onDelFun();
							}}
						>
							Delete
						</Button>
					)}
					{action && (
						<Button
							style={{ margin: 7 }}
							disabled={
								isLogging ||
								(data && formRef.current && !isEmpty(formRef.current.errors))
							}
							onClick={(e) => {
								e.stopPropagation();
								console.log(' data formRef.current ', formRef.current);
								if (data && formRef.current) {
									console.log(' data formRef.current inner', formRef.current);

									formRef.current.handleSubmit(e);
								} else {
									console.log(' data formRef.current outer');

									onActionFun(initData);
								}
							}}
						>
							{isLogging && (
								<Spinner
									as='span'
									animation='border'
									role='status'
									aria-hidden='true'
								/>
							)}
							<span>{action}</span>
						</Button>
					)}
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default memo(InputModal);
