import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from '../constants/Africa';
import { countryCodes } from '../constants/CountryCodes';
import { prices } from '../constants/AppData';

import {
	currentAction,
	fetchLimit,
	fetchTotalJobsResults,
	selLocAction,
} from '../actions';
import { Regions } from '../hooks/useRegions';
import { googleMapsApiKey } from '../config/google_maps';
import { find, filter, pick } from 'lodash';
import SplashScreen from '../components/splashscreen/SplashScreen';
import { getAuth, onIdTokenChanged } from 'firebase/auth';

import { firebase } from '../config/fbConfig';

const auth = getAuth(firebase);

function AuthIsLoaded({ children }) {
	//const [current, setstate] = useState(false);
	const dispatch = useDispatch();
	//console.log('check currentUser state ', current);
	const { currency, currently_in, phone_code, code } = useSelector(
		({
			cla_menuReducer: {
				loc: { currency, currently_in, phone_code, code },
			},
		}) => {
			return { currency, currently_in, phone_code, code };
		},
	);
	const currentFb = useSelector(
		({ cla_authReducer }) => cla_authReducer?.current,
	);
	const userCallback = useCallback(
		async (user, currentUser) => {
			if (user) {
				if (currentUser?.reAuth) {
					dispatch(
						currentAction({ ...currentUser, [currentUser.user_type]: true }),
					);
				}

				const { saveUserToFirestore } = await import('../actions');
				if (!user.signup_compelete && !currentUser.emailVerified) {
					dispatch(
						saveUserToFirestore({ ...user, signup_compelete: true }, null),
					);
				}
				const { currency: curr } = user;
				if (!curr) {
					dispatch(
						saveUserToFirestore(
							{ ...user, currency, currently_in, phone_code, code },
							null,
						),
					);
				}
			}
		},
		[currency, currently_in, phone_code, code],
	);

	const showPosition = useCallback((position) => {
		console.log(
			'Latitude: ',
			position.coords.latitude,
			'<br>Longitude: ',
			position.coords.longitude,
			'key ',
			googleMapsApiKey,
		);
		fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=${googleMapsApiKey}`,
		)
			.then((res) => res.json())
			.then(async (res) => {
				try {
					console.log('location all res ', res.results);
					const country = find(res.results[0].address_components, function (o) {
						return o.types.includes('country');
					});
					const region = find(res.results[0].address_components, function (o) {
						return o.types.includes('administrative_area_level_2');
					}).long_name;
					const town = find(res.results[0].address_components, function (o) {
						return o.types.includes('locality');
					}).long_name;

					const regions = filter(Regions(country.long_name), function (o) {
						console.log('country splitAtom[0] ', region, 'regions ', o);

						return region.includes(o);
					});
					dispatch(
						fetchTotalJobsResults('active_users', country.long_name, null),
					);
					let currency = find(countries, {
						countryName: country.long_name,
					}).currencyCode;
					const position = Object.keys(prices[0][0].val).includes(currency);
					currency = position ? currency : 'USD';
					const phone_code = find(countryCodes, function (o) {
						return o.name === country.long_name;
					}).dial_code;
					console.log('location phone_code ', phone_code);

					dispatch(
						selLocAction({
							code: country.short_name,
							region: regions.length > 0 ? regions[0] : '',
							town,
							phone_code,
							currently_in: country.long_name,
							currency,
						}),
					);
				} catch (err) {
					console.log('currently_in err ', err);
				}
			})
			.catch((err) => alert(err));
	}, []);
	const getLocation = useCallback(() => {
		dispatch(fetchLimit());

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			alert('Geolocation is not supported by this browser.');
		}
	}, [showPosition]);

	useEffect(() => {
		getLocation();
	}, [currentFb?.userChecked, getLocation]);
	const authCallback = useCallback(
		async (currentUser) => {
			const { isEmpty } = currentUser;
			if (!isEmpty) {
				dispatch(currentAction({ ...currentUser, reAuth: currentFb?.reAuth }));
				const { getDocFirestore } = await import('../actions');

				dispatch(
					getDocFirestore(
						'users',
						currentUser.uid,
						userCallback,
						true,
						currentUser,
					),
				);
			} else {
				dispatch(currentAction(currentUser));
			}
		},
		[userCallback, currentFb?.reAuth],
	);
	//useAuth(init,authCallback)
	const onAuthStateChanged = useCallback(
		async (currentUser) => {
			if (currentUser) {
				const { getIdTokenResult } = await import('firebase/auth');

				getIdTokenResult(currentUser, true).then(async (idTokenResult) => {
          const user={
            ...currentUser,
            ...pick(idTokenResult.claims,['admin','super_admin','verified','parent','childcarer']),
            isEmpty:false,
            userChecked: true 
          }
					authCallback(user);
					const { analytics } = await import('../config/fbConfig');
					const { setUserId, setUserProperties } = await import(
						'firebase/analytics'
					);

					setUserId(analytics, currentUser.uid);
					setUserProperties(
						analytics,
						pick(currentUser, [
							'childcarer',
							'parent',
							'verified',
							'super_admin',
							'admin',
						]),
					);
				});
			} else {
				authCallback({ userChecked: true, isEmpty: true });
			}
		},
		[authCallback],
	);
	useEffect(() => {
		const subscriber = onIdTokenChanged(auth, onAuthStateChanged);
		return subscriber; // unsubscribe on unmount
	}, [onAuthStateChanged]);
	if (!currentFb?.userChecked) return <SplashScreen />;
	return children;
}

export default AuthIsLoaded;
