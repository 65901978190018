import React, { useEffect } from 'react';

const ProcessPawaPayPayments = ({amount}) => {
    

    useEffect(() => {
       
            const postData = async () => {
                try {
                    const pawa_pay_token = process.env.REACT_APP_PAWAPAY_PAYMENTS_TOKEN;
                    const response = await fetch('http://localhost:3001/create-payment-session', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + pawa_pay_token
                        },
                        body: JSON.stringify({
                            amount: amount 
                        })
                    });
                    const data = await response.json();
                    console.log('STATUS '+data)
                    const redirectUrl = data.redirectUrl;
                    console.log('Payment URL: ' + redirectUrl);
                    window.location.href = redirectUrl;
                } catch (error) {
                    console.error('Error posting data:', error);
                }
            };

            postData();
       
    }, []);



    

    return (
        <div>

        </div>
    );
};

export default ProcessPawaPayPayments;
