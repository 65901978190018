import { CHANGE_CART, CHANGE_PATH, SCROLL, SEL, SEL_DATE,EMAIL, REF } from '../actions/types.js';
import { newCart } from '../constants/AppData.js';

const INIT_STATE = {
  loc: {
    currently_in: 'Zambia',
    region: '',
    town: '',
    code: 'ZM',
    phone_code: '+260',
    currency: 'ZMW',
  },
  scrollTo:false,
  days: new Date(),
  change_path: 'false',
  sel_cart: newCart,
  email:null,
  ref_id:null
};
const toggleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEL: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        loc: action.payload,
      };
    }
    case REF: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        ref_id: action.payload,
      };
    }
    case EMAIL: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        email: action.payload,
      };
    }
    case SCROLL: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        scrollTo: action.payload,
      };
    }
    case CHANGE_CART: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        sel_cart: action.payload,
      };
    }
    case CHANGE_PATH: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        change_path: action.payload,
      };
    }
    case SEL_DATE: {
      console.log('currently_in action.payload ', action.payload);

      return {
        ...state,
        days: action.payload,
      };
    }

    default:
      return state;
  }
};
export default toggleReducer;
