export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP ERROR';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';
export const DISABLE = 'DISABLE';
export const CLEAR = 'CLEAR';
export const RES = 'RES';
export const TOTAL = 'TOTAL';
export const CREATE_FORM = 'CREATE_FORM';
export const REFORM = 'REFORM';
export const NOTE_RES = 'NOTE_RES';
export const POST = 'POST';
export const TYPE_RES = 'TYPE_RES';
export const LENDER_RES = 'LENDER_RES';
export const UPDATE = 'UPDATE';
export const UPDATE_FORM = 'UPDATE_FORM';
export const CURRENT_ADMIN = 'CURRENT_ADMIN';
export const UPDATE_INDEX = 'UPDATE_INDEX';
export const UPDATE_FORM_INPUTS = 'UPDATE_FORM_INPUTS';
export const UPDATE_INFO = 'UPDATE_INFO';
export const QUERRY_FIRESTORE_NOT_FOUND = 'QUERRY_FIRESTORE_NOT_FOUND';

export const SIGNUP_FIRESTORE_SUCCESS = 'SIGNUP_FIRESTORE_SUCCESS';
export const SIGNUP_FIRESTORE_ERROR = 'SIGNUP_FIRESTORE_ERROR';
export const MSG_SIGNUP_FIRESTORE_SUCCESS = 'successfully added to firestore';
export const MSG_SIGNUP_FIRESTORE_ERROR = 'error adding to firestore.';
export const STORE_ID = 'UID';
export const VER = 'VER';
export const LIMIT = 'LIMIT';
export const QUERRY_FIRESTORE_SUCCESS = 'QUERRY_FIRESTORE_SUCCESS';
export const QUERRY_FIRESTORE_ERROR = 'QUERRY_FIRESTORE_ERROR';
export const MSG_QUERRY_FIRESTORE_SUCCESS = 'successfully added to firestore';
export const MSG_QUERRY_FIRESTORE_ERROR = 'error adding to firestore.';
export const CURRENT_USER = 'CURRENT_USER';

export const MSG_SIGNUP_SUCCESS =
  'Your account was successfully created! Please verify your e-mail address in the e-mail we just sent to you.';
export const MSG_SIGNUP_ERROR = 'Something went wrong. Please try again.';
export const MSG_SIGNIN_SUCCESS = 'Sign in successful';
export const MSG_SIGNIN_ERROR = 'Invalid login credentials';
export const MSG_SIGNOUT_SUCCESS = 'Sign out successful';
export const MSG_SIGNOUT_ERROR = 'There was an issue signing out.';

export const FEATURED = `If you are in a hurry to find your ideal caregiver job, becoming a "featured" caregiver is a great way to ensure that you are seen quickly by all of our parents and top caregiver agencies.

As a featured caregiver, your profile will be emailed to all of our parents and will appear at the top of the caregiver search.

For just <$>, you can be featured as a caregiver for 24 hours on the day of your choosing.

Simply choose the date you want to be featured as a caregiver and proceed to our secure payment page.`;

export const ABOUT_PAY = `It only takes 5 minutes to create a job advertisement that will appear on our job pages and be emailed to the nannies who meet your requirements. Those who are interested will get in touch with you.

You can also search for and contact up to 1000 nannies from their profiles.

Regardless of how you use CaregiverAfrica, you will find the right caregiver for you and your family.

Pay a one-time fee of <$> for three months of access; there are no ongoing subscription fees.`;
