import {
	SIGNUP_ERROR,
	SIGNOUT_ERROR,
	DISABLE,
	CLEAR,
	VER,
	CURRENT_USER,
	CURRENT_ADMIN,
} from '../constants';
import { firebase } from '../config/fbConfig';

export const verAction = (payload) => ({ type: VER, payload });
export const currentAction = (payload) => ({ type: CURRENT_USER, payload });
export const currentAdminAction = (payload) => ({
	type: CURRENT_ADMIN,
	payload,
});

export const disableAction = (payload) => ({ type: DISABLE, payload });
export const clearAction = () => ({ type: CLEAR, payload: '' });

export const resetPassword = (emailAddress, url, callback) => async () => {
	const actionCodeSettings = {
		url: url
			.replace(/%3A/g, ':')
			.replace(/%2F/g, '/')
			.replace(/%20/g, ' ')
			.replace(/[AA]+/g, '&'),
	};
	const { sendPasswordResetEmail, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	sendPasswordResetEmail(auth, emailAddress, actionCodeSettings)
		.then(function () {
			// Email sent.
			callback(emailAddress);
		})
		.catch(function (error) {
			// An error happened.
			callback(null, error.message);
		});
};
export const updateUserPassword = (password, callback) => async () => {
	const { updatePassword, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	const user = auth.currentUser;

	updatePassword(user, password)
		.then(function () {
			// Update successful.
			callback();
		})
		.catch(function (error) {
			// An error happened.
			callback(error);
		});
};
export const delUser = () => async () => {
	const { deleteUser, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	const user = auth.currentUser;

	deleteUser(user)
		.then(function () {
			// User deleted.
		})
		.catch(function (error) {
			// An error happened.
		});
};
export const reAuth = (credential, callback) => async () => {
	const { reauthenticateWithCredential, getAuth } = await import(
		'firebase/auth'
	);
	const auth = getAuth(firebase);
	const user = auth.currentUser;

	reauthenticateWithCredential(user, credential)
		.then(function () {
			// User re-authenticated.
			callback();
		})
		.catch(function (error) {
			// An error happened.
			callback(error.message);
		});
};
export const resetEmail = (data, callback, from) => async (dispatch) => {
	const { updateEmail, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	const user = auth.currentUser;

	updateEmail(user, data.email.val)
		.then(function () {
			// Update successful.
			sendVerifyEmail(data, from, callback);
		})
		.catch(function (error) {
			// An error happened.
			callback(null, error.message);
		});
};

const sendVerifyEmail = async (payload, path, callback) => {
	try {
		const url= decodeURIComponent(path)
		console.log('pathName path ', path,' url ',url);
		
		const actionCodeSettings = {
			url,
		};
		const { sendEmailVerification, getAuth } = await import('firebase/auth');
		const auth = getAuth(firebase);
		const user = auth.currentUser;

		sendEmailVerification(user, actionCodeSettings).then(
			() => {
				console.log('sign inner auth  successfully ', user.email);

				callback(payload ? payload : user.email);
			},
			function (err) {
				// An error happened.
				console.log('sign inner auth  err ', err.message);

				callback(null, err.message);
			},
		);
	} catch (err) {
		callback(null, 'Please try again.');
	}
};
export const expVerificationEmail = (url, callback) => async () => {
	sendVerifyEmail(null, url, callback);
};
const getProvider = async (opt) => {
	const { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } =
		await import('firebase/auth');

	return opt === 'Facebook'
		? new FacebookAuthProvider()
		: opt === 'Google'
		? new GoogleAuthProvider()
		: new TwitterAuthProvider();
};
export const signinWithSocial = (opt) => async () => {
	const { signInWithRedirect, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	const provider = await getProvider(opt);

	signInWithRedirect(auth, provider);
};
export const signinResult = (callback) => async () => {
	const { getRedirectResult, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);

	getRedirectResult(auth)
		.then(async (result) => {
			// The signed-in user info.
			if (result) {
				const user = result.user;
				console.log('google res ', result, 'user ', user);

				callback(null, user);
			} else {
				callback();
			}
		})
		.catch((error) => {
			const errorCode = error.code;

			console.log(
				'google res error.message ',
				error.message,
				'errorCode ',
				errorCode,
			);

			callback(
				errorCode === 'auth/popup-closed-by-user' ? null : error.message,
			);
		});
};
export const signup =
	(signUser, password, pathName, callback) => async (dispatch) => {
		console.log('sign inner auth ', signUser, 'pathName ', pathName);
		const { createUserWithEmailAndPassword, getAuth } = await import(
			'firebase/auth'
		);
		const auth = getAuth(firebase);
		try {
			return createUserWithEmailAndPassword(auth, signUser.email, password)
				.then((userCredential) => {
					const user = userCredential.user;
					let path = pathName;
					const { user_type } = signUser;
					if (path.includes('my_account') && !path.includes(user_type)) {
						path = path.replace(
							user_type === 'parent' ? 'childcarer' : 'parent',
							user_type,
						);
					}
					console.log('sign inner auth ', signUser, 'pathName ', path);

					sendVerifyEmail({ ...signUser, user_id: user.uid }, path, callback);
				})
				.catch(function (error) {
					try {
						let msg;
						switch (error.code) {
							case 'auth/email-already-in-use':
								msg = 'Email already in use';
								break;
							case 'auth/invalid-email':
								msg = 'Invalid Email';
								break;
							case 'auth/weak-password':
								msg = 'Password should be 8 characters or longer';
								break;
							default:
								msg = 'Error during sign up';
						}
						callback(null, msg);
					} catch (err) {
						callback(null, 'Please try again.');
					}
					dispatch({
						type: SIGNUP_ERROR,
						payload: 'Something went wrong. Please try again.',
					});
				});
		} catch (err) {
			callback(null, 'Something went wrong. Please try again.');

			dispatch({
				type: SIGNUP_ERROR,
				payload: 'Something went wrong. Please try again.',
			});
		}
	};
export const signin = (email, password, callback) => async () => {
	try {
		console.log('sign in');
		const {
			signInWithEmailAndPassword,
			onAuthStateChanged,
			getIdTokenResult,
			getAuth,
		} = await import('firebase/auth');
		const auth = getAuth(firebase);
		return signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				console.log('sign in success');
				onAuthStateChanged(
					auth,
					(user) => {
						console.log('sign inner auth ');
						getIdTokenResult(user).then((idTokenResult) => {
							user.parent =
								idTokenResult.claims.parent !== undefined &&
								idTokenResult.claims.parent !== null
									? idTokenResult.claims.parent
									: false;

							callback(null, user.parent, user.uid);
						});
					},
					function (error) {
						console.log(error);
						try {
							callback(error.message);
						} catch (err) {
							callback('Please try again.');
						}
					},
				);
			})
			.catch((err) => {
				console.log('sign in err in ' + err);
				try {
					callback(err.message);
				} catch (err) {
					callback('Please try again.');
				}
			});
	} catch (err) {
		console.log('sign in err inner ' + err);
		try {
			callback(err.message);
		} catch (err) {
			callback('Please try again.');
		}
	}
};
export const handleReset =
	(newPassword, actionCode, authCallback) => async () => {
		// Save the new password.
		const { confirmPasswordReset, getAuth } = await import('firebase/auth');
		const auth = getAuth(firebase);
		try {
			await confirmPasswordReset(auth, actionCode, newPassword);
			authCallback(null, newPassword);
		} catch (error) {
			// Error occurred during confirmation. The code might have expired or the
			// password is too weak.
			authCallback(error.message, null, null);
		}
	};

// Same code as above except it's for merging with a Google account
export const mergeAndUnmerge = (providerIndex, providerStr) => async () => {
	const { getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	const user = auth.currentUser;
	const provider = await getProvider(providerStr);

	if (user) {
		if (providerIndex !== -1) unmerge(user, providerIndex);
		else merge(user, provider);
	}
};

export const merge = (previousUser, provider) => async () => {
	// provider can be "google.com" or "twitter.com" etc..
	// We're basically signing in the user a second time with the social media account
	// that they want it to be merged with the current one.
	const { signInWithPopup, getAuth, signInWithCredential } = await import(
		'firebase/auth'
	);
	const auth = getAuth(firebase);
	signInWithPopup(provider).then((result) => {
		const secondAccountCred = provider.credentialFromResult(result);
		// Then we're deleting the current social media provider to prevent any conflicts in case it's used to connect to another account on your app.
		// The current user here means the one he just signed in with clicking on the merge button.
		auth.currentUser
			.delete()
			.then(() => {
				// Now we're connecting the previousUser which represents the provider account that the user used to
				// sign in to the app at the very beginning.
				return previousUser.linkWithCredential(secondAccountCred);
			})
			.then(() => {
				// Reconnecting to the app.
				signInWithCredential(secondAccountCred);
				console.log('Accounts linked successfully!');
			});
	});
};
export const mergewithEmail = (email, callBack) => async () => {
	// provider can be "google.com" or "twitter.com" etc..
	// We're basically signing in the user a second time with the social media account
	// that they want it to be merged with the current one.
	const {
		EmailAuthProvider,
		getAuth,
		signInWithCredential,
		signInWithEmailAndPassword,
	} = await import('firebase/auth');
	const auth = getAuth(firebase);

	const previousUser = auth.currentUser;
	await signInWithEmailAndPassword(
		auth,
		email,
		process.env.REACT_APP_PASSWORD_KEY,
	);
	const secondAccountCred = EmailAuthProvider.credential(
		email,
		process.env.REACT_APP_PASSWORD_KEY,
	);
	// Then we're deleting the current social media provider to prevent any conflicts in case it's used to connect to another account on your app.
	// The current user here means the one he just signed in with clicking on the merge button.

	auth.currentUser
		.delete()
		.then(() => {
			// Now we're connecting the previousUser which represents the provider account that the user used to
			// sign in to the app at the very beginning.
			return previousUser.linkWithCredential(secondAccountCred);
		})
		.then(async () => {
			// Reconnecting to the app.
			await signInWithCredential(secondAccountCred);
			console.log('Accounts linked successfully!');
			callBack(email);
		});
};
export const mergewithNewEmail = (email, password, callBack) => async () => {
	// provider can be "google.com" or "twitter.com" etc..
	// We're basically signing in the user a second time with the social media account
	// that they want it to be merged with the current one.
	const { EmailAuthProvider, getAuth, signInWithCredential } = await import(
		'firebase/auth'
	);
	const auth = getAuth(firebase);

	const previousUser = auth.currentUser;

	const secondAccountCred = EmailAuthProvider.credential(email, password);
	// Then we're deleting the current social media provider to prevent any conflicts in case it's used to connect to another account on your app.
	// The current user here means the one he just signed in with clicking on the merge button.

	previousUser
		.linkWithCredential(secondAccountCred)
		.then(async () => {
			// Reconnecting to the app.
			await signInWithCredential(secondAccountCred);
			console.log('Accounts linked successfully!');
			callBack();
		})
		.catch((error) => {
			console.log('Account linking error', error.message);
			callBack(email, error.message);
		});
};
export const unmerge = (user, providerIndex) => async () => {
	user
		.unlink(user.providerData[providerIndex].providerId)
		.then(() => {
			console.log('Unlinked successfully!');
		})
		.catch((error) => {
			console.error(error);
		});
};
export const handleResetPassword =
	(actionCode, passwordCallback) => async () => {
		// Localize the UI to the selecteuage as determined by th
		// parameter.
		const { verifyPasswordResetCode, getAuth } = await import('firebase/auth');
		const auth = getAuth(firebase);
		// Verify the password reset code is valid.
		return verifyPasswordResetCode(auth, actionCode)
			.then((email) => {
				// TODO: Show the reset screen with the user's email and ask the user for
				// the new password.
				passwordCallback(null, email);
			})
			.catch((error) => {
				// Invalid or expired action code. Ask user to try to reset the password
				// again.
				passwordCallback(error.message, null, null);
			});
	};

export const handleRecoverEmail = (actionCode, authCallback) => async () => {
	// Confirm the action code is valid.
	const { checkActionCode, applyActionCode, getAuth } = await import(
		'firebase/auth'
	);
	const auth = getAuth(firebase);
	return checkActionCode(auth, actionCode)
		.then(() => {
			// Get the restored email address.
			// Revert to the old email.
			return applyActionCode(auth, actionCode);
		})
		.then(() => {
			// Account email reverted to restoredEmail

			// TODO: Display a confirmation message to the user.

			// You might also want to give the user the option to reset their password
			// in case the account was compromised:
			authCallback(null, 'Account email reverted to restoredEmail');
		})
		.catch((error) => {
			// Invalid code.\
			authCallback(error.message, null, null);
		});
};
export const handleVerifyEmail = (actionCode, authCallback, id) => async () => {
	// Localize the UI to the selecteuage as determined by th
	// parameter.
	// Try to apply the email verification code.
	const { applyActionCode, getAuth } = await import('firebase/auth');
	const auth = getAuth(firebase);
	return applyActionCode(auth, actionCode)
		.then(() => {
			// Email address has been verified.

			// TODO: Display a confirmation message to the user.
			// You could also provide the user with a link back to the app.

			// TODO: If a continue URL is available, display a button which on
			// click redirects the user back to the app via continueUrl with
			// additional state determined from that URL's parameters.
			authCallback(null, 'Email address has been verified', id);
		})
		.catch((error) => {
			// Code is invalid or expired. Ask the user to verify their email address
			// again.
			authCallback(error.message, null, null);
		});
};
export const signout = (callBack) => async (dispatch) => {
	try {
		const { signOut, getAuth } = await import('firebase/auth');
		const auth = getAuth(firebase);
		signOut(auth)
			.then(() => {
				if (callBack) callBack();
			})
			.catch(() => {
				dispatch({
					type: SIGNOUT_ERROR,
					payload: 'There was an issue signing out.',
				});
				dispatch({
					type: CURRENT_USER,
					payload: { isEmpty: true },
				});
			});
	} catch (err) {
		dispatch({
			type: SIGNOUT_ERROR,
			payload: 'There was an issue signing out.',
		});
	}
};
