//import action-types
import {
	QUERRY_FIRESTORE_SUCCESS,
	UPDATE,
	TOTAL,
	CREATE_FORM,
	REFORM,
	UPDATE_FORM,
	POST,
	LIMIT,
} from '../constants';
import { user, post } from '../constants/AppData';
import { form_names } from '../constants/JobForms';
//initialize state
const INITIAL_STATE = {
	user,
	post,
	form: null,
	reform: null,
	create_form: form_names,
	limit: false,
	total: 0,
};

//reducer
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action) {
	//write conditional to handle action-response
	if (action.type === QUERRY_FIRESTORE_SUCCESS) {
		return {
			...state,
			user: action.payload
				? { ...state.user, ...action.payload }
				: action.payload,
		};
	} else if (action.type === UPDATE) {
		console.log('user storeMsg ', action.payload);

		return { ...state, user: action.payload };
	} else if (action.type === LIMIT) {
		return { ...state, limit: action.payload };
	} else if (action.type === POST) {
		console.log('user storeMsg ', action.payload);

		return { ...state, post: action.payload };
	} else if (action.type === TOTAL) {
		console.log('user storeMsg ', action.payload);

		return { ...state, total: action.payload };
	} else if (action.type === UPDATE_FORM) {
		return { ...state, form: action.payload };
	} else if (action.type === CREATE_FORM) {
		return { ...state, create_form: action.payload };
	} else if (action.type === REFORM) {
		return { ...state, reform: action.payload };
	} else {
		return state;
	}
}
