import { africa } from "./Africa";
import { dropRight } from "lodash";
import { carer } from "./HomeImg";

export const params = [
    {
      title: ['Find a job as', 'Find a'],
      name: 'positions',
  
      info: [
        'Any Position',
        ...dropRight(carer, 2).map((position) => position.title),
      ],
    },
    {
      title: ['in', 'in'],
      name: 'currently_in',
  
      info: ['Any country', ...africa],
    },
  ];

  export const allRoles = dropRight(carer, 2).map((el) => el.title);
