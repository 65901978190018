import { useHistory } from 'react-router';
import { LIGHTBLUE } from '../constants/Colors';

import styled from 'styled-components';
import { dropRight } from 'lodash';
import { icons } from '../constants/SocialMedia';
const Div = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	p {
		color: ${LIGHTBLUE};
		span {
			cursor: pointer;
			:hover {
				color: black;
			}
		}
	}
	label {
		color: grey;
	}
	@media (max-width: 800px) {
		align-items: center;
		justify-content: space-around;
		flex-flow: column nowrap;
	}
`;

function Footer() {
	const history = useHistory();

	return (
		<>
			<p
				style={{
					fontSize: '0.9rem',

					color: 'gray',
				}}
			>
				Caregiverafrica.com does not employ any caregiver and is not responsible
				for the conduct of any user of our site. All information in member
				profiles, job posts, applications, and messages is created by users of
				our site and not generated or verified by Caregiverafrica.com. You need
				to do your own diligence to ensure the job or caregiver you choose is
				appropriate for your needs and complies with applicable laws.
			</p>
			<Div>
				<div>
					<p>
						<span onClick={() => history.push('/policy')}>
							{' '}
							Terms & Conditions
						</span>{' '}
						| <span onClick={() => history.push('/contactus')}>Contact Us</span>
					</p>
				</div>
				<div>
					{dropRight(icons).map((item, idx) => (
						<item.icon
							key={idx}
							color={item.color}
							style={{ margin: '0px 5px', cursor: 'pointer' }}
							size='1.4em'
						/>
					))}
				</div>
				<label>{`Caregiver africa @${new Date().getFullYear()}`}</label>
			</Div>
		</>
	);
}

export default Footer;
