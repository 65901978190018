import { allCountries } from '../constants/Countries';
import { map, find } from 'lodash';

export  function Regions(origin) {
  let regions = [];
  try {
    regions = map(
      find(allCountries, { countryName: origin })['regions'],
      'name'
    );
    console.log('regions ', regions);
  } catch (err) {}
  return regions;
}
