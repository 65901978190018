import { africa } from "./Africa";
import { educationOpt, experienceOpt, languagesOpt, liveOpt, rangeOpt, selOpt, sexOpt, termOpt, workOpt } from "./AppData";
import { allRoles } from "./SearchParams";

export const job_form = {
    Your_contact_information: [
      {
        name: 'email',
        title: 'Email address (not shown)*',
        type: 'email',
        hint: 'email',
      },
      {
        name: 'contact',
        title: 'Contact name*',
  
        type: 'text',
        hint: 'Contact name*',
      },
    ],
    Job_details: [
      {
        name: 'position',
        title: 'Job type*',
  
        type: 'select',
        payload: ['Please select', ...allRoles],
      },
      {
        name: 'start_date',
        title: 'Enter start date*',
  
        type: 'date',
        payload: [new Date()],
      },
      {
        name: 'salary',
        title: 'Salary*',
  
        type: 'text',
        hint: 'salary',
      },
      {
        name: 'hours',
        title: 'Hours*',
  
        type: 'text',
        hint: '30-40 hours per week',
      },
  
      {
        name: 'duration',
        title: 'Duration*',
  
        type: 'select',
        payload: termOpt.slice(1),
      },
    ],
    Job_requirements: [
      {
        name: 'sex',
        title: 'Sex preferrence',
  
        type: 'select',
  
        payload: sexOpt,
      },
      {
        name: 'qualifications',
        title: 'Qualifications',
  
        type: 'text',
        hint: 'Qualifications',
      },
  
      {
        title: 'Education level',
        name: 'education',
  
        type: 'select',
  
        payload: educationOpt,
      },
      {
        title: 'Number of kids to care for',
        name: 'number_kids',
        type: 'select',
  
        payload: experienceOpt,
      },
      {
        title: 'Age range for kid(s)',
        name: 'age_range',
        type: 'select',
  
        payload: rangeOpt,
      },
      {
        title: 'Comfortable with pets',
        name: 'pets',
  
        type: 'select',
  
        payload: selOpt.slice(1),
      },
      {
        title: 'CPR / First Aid trained',
        name: 'cpr',
  
        type: 'select',
  
        payload: selOpt.slice(1),
      },
  
      {
        name: 'driver',
        title: 'Driving licence',
  
        type: 'select',
  
        payload: selOpt.slice(1),
      },
      {
        name: 'full_part_time',
        title: 'Employment type',
  
        type: 'select',
  
        payload: workOpt.slice(1),
      },
      {
        name: 'live_in_out',
        title: 'Live in or live out',
  
        type: 'select',
  
        payload: liveOpt.slice(1),
      },
      {
        name: 'language',
        title: 'Language',
  
        type: 'select',
  
        payload: ['Not specified', ...languagesOpt],
      },
    ],
    Job_location: [
      {
        name: 'currently_in',
        title: 'Country*',
  
        type: 'select',
  
        payload: ['Please select', ...africa],
      },
    ],
  };
  export const form_names = Object.values(job_form).reduce((result, value) => {
    const _nam = value.reduce(
      (res, val) => ({
        ...res,
        [val.name]: val.payload ? val.payload[0] : '',
      }),
      { info: '', terms: false }
    );
  
    return { ...result, ..._nam };
  }, {});
  
  export const subJob = {
    title: "Parents, here's an easy way to find your ideal caregiver.",
    subtitle: 'Today there are 10073 childcarers looking for jobs',
    btn: 'Submit a Job',
    path: '/createjob',
    payload: form_names,
    steps: [
      'Caregivers will contact you after you post your job.',
      'Your job is emailed to caregivers who are a good fit for you.',
    ],
  };

  