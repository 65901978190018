import {
  QUERRY_FIRESTORE_SUCCESS,
  UPDATE,
  UPDATE_FORM,
  TOTAL,
  CREATE_FORM,
  POST,
  REFORM,
  TYPE_RES,
  LENDER_RES,
  LIMIT,
} from "../constants";
//import firebase object from firbase.js
import { firebase } from "../config/fbConfig";

import {
  isEqual,
  includes,
  pick,
  map,
  find,
  findIndex,
  omit,
  orderBy as orderByLodash,
} from "lodash";
import useUUUID from "../hooks/useUUUID";

export const updateFireAction = (payload) => ({ type: UPDATE, payload });
export const updateLimitAction = (payload) => ({ type: LIMIT, payload });
export const updateFormAction = (payload) => ({ type: UPDATE_FORM, payload });
export const updateReformAction = (payload) => ({ type: REFORM, payload });
export const updateCreateAction = (payload) => ({ type: CREATE_FORM, payload });
export const updatePostAction = (payload) => ({
  type: POST,
  payload,
});
export const updateSearchByTypeAction = (payload) => ({
  type: TYPE_RES,
  payload,
});
export const updateSearchByLenderAction = (payload) => ({
  type: LENDER_RES,
  payload,
});

export const delPostComment = (path, user_id, callback) => async () => {
  try {
    const { deleteDoc, doc, getFirestore } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    await deleteDoc(doc(db, path, user_id));

    if (callback !== null) callback();
  } catch (err) {
    console.log("saving to fb why null ", err);
    callback(err.message);
  }
};

export const updateDoc = (path, data, callback) => async () => {
  try {
    const { setDoc, doc, getFirestore } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    await setDoc(doc(db, path), data, { merge: true });

    if (callback !== null) callback();
  } catch (err) {
    console.log("saving to fb why null ", err);
    callback(err.message);
  }
};
export const saveUserToFirestore =
  (userData, callback, data, picker) => async (dispatch, getState) => {
    const reduxUser = getState().cla_datastore.user;
    let newUser = userData || {
      ...pick(reduxUser, picker),
      ...data,
    };
    Object.keys(newUser).forEach(
      (key) => newUser[key] === undefined && delete newUser[key]
    );
    console.log("saving to fb why ", newUser, "path ");
    const {
      query,
      collection,
      where,
      limit,
      getDocs,
      setDoc,
      doc,
      getFirestore,
    } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    try {
      const {
        user_id,
        date_updated,
        date_created,
        dob,
        start_date,
        jobs,
        featured,
        notes,
        isCompelete,
        status,
        username,
        user_type,
      } = newUser;
      const uid = user_id || reduxUser.user_id;
      const { getStamp } = await import("../utils/FbStamp");
      console.log("get payload ", uid);
      if (date_updated) {
        newUser = {
          ...newUser,
          date_updated: getStamp(date_updated),
        };
      }
      if (notes) {
        newUser = {
          ...newUser,
          notes: getStamp(notes),
        };
      }
      if (featured) {
        newUser = {
          ...newUser,
          featured: getStamp(featured),
        };
      }
      if (start_date) {
        newUser = {
          ...newUser,
          start_date: getStamp(start_date),
        };
      }
      if (jobs) {
        newUser = {
          ...newUser,
          jobs: getStamp(jobs),
        };
      }
      if (dob) {
        newUser = {
          ...newUser,
          dob: getStamp(dob),
        };
      }
      if (date_created) {
        newUser = {
          ...newUser,
          date_created: getStamp(date_created),
        };
      }
      if (isCompelete !== undefined) {
        let newCompelete = isCompelete;
        if (user_type === "childcarer") {
          const { basic_information, roles, personal_description, uploads } =
            status;
          const { positions = [] } = reduxUser;
          newCompelete = basic_information && personal_description && uploads;
          if (!positions.includes("cook")) {
            newCompelete = newCompelete && roles;
          }
          const { radioValue } = newUser;
          if (radioValue !== undefined) {
            newUser = {
              ...omit(newUser, "radioValue"),
              live: newCompelete && radioValue === "0",
            };
          }
        }
        const obj = { isCompelete: newCompelete };
        newUser = {
          ...newUser,
          ...obj,
        };
        const { logEvent } = await import("firebase/analytics");
        const { analytics } = await import("../config/fbConfig");

        logEvent(analytics, "profile", obj);
      }
      if (username) {
        const q = query(
          collection(db, "users"),
          where("username", "==", username),
          limit(1)
        );

        const querySnapshot = await getDocs(q);
        const name =
          !querySnapshot.empty && uid !== querySnapshot?.docs[0].id
            ? username + useUUUID().substring(0, 4)
            : username;
        newUser = {
          ...newUser,
          username: name,
        };
      }
      console.log("saving to fb final ", newUser);

      dispatch({
        type: QUERRY_FIRESTORE_SUCCESS,
        payload: newUser,
      });

      setDoc(doc(db, "users", uid), newUser, { merge: true })
        .then(() => {
          console.log("success adding document: ", newUser);
          if (callback !== null) callback(data || newUser);
        })
        .catch((error) => {
          console.log("Error adding document: ", error);
          if (callback !== null) callback(null, error.message);
        });
    } catch (err) {
      console.log("Error adding document outer: ", err);

      callback(null, err.message);
    }
  };
export const getCount = (post, queryData, callback) => async () => {
  try {
    const {
      query,
      collectionGroup,
      where,
      getDocs,
      getDoc,
      doc,
      getFirestore,
    } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    if (queryData) {
      let dt = new Date();
      dt.setDate(dt.getDate() + 1);
      const { getStamp } = await import("../utils/FbStamp");
      const start_date = getStamp(dt);
      let dt1 = new Date();
      dt1.setDate(dt1.getDate() + 1);

      const end_date = getStamp(dt1);
      const q = query(
        collectionGroup(db, post),


        where("date_created", ">=", start_date),
        where("date_created", "<=", end_date)
      );

      getDocs(q)
        .then((querySnapshot) => {
          return callback({ total: querySnapshot.size });
        })
        .catch((err) => {
          return console.log("on err ", err);
        });
    } else {
      const item = await getDoc(doc(db, post));
      console.log("counter ", item.data(), "doc ", post);
      return callback(item.data());
    }
  } catch (error) {
    return console.log("on err ", error);
  }
};
export const isApplied =
  (user_id, type, callback, field, field2) => async () => {
    const {
      query,
      collection,
      where,
      orderBy,
      limit,
      onSnapshot,
      getFirestore,
    } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    try {
      let q;
      console.log("query user_id ", user_id, "type ", type);

      if (field2) {
        q = query(
          collection(db, "users", user_id, type),
          where(field.key, "==", field.value),
          where(field2.key, "==", field2.value),
          orderBy("posted", "desc"),
          limit(1)
        );
      } else {
        q = query(
          collection(db, "users", user_id, type),
          where(field.key, "==", field.value),
          orderBy("posted", "desc"),
          limit(1)
        );
      }
      const sub = onSnapshot(
        q,
        (query) => {
          console.log("query ", query.empty);
          let action = false;
          let data = {};
          if (!query.empty) {
            const doc = query.docs[0].data();
            data = {
              ...doc,
              posted: doc.posted.toDate(),
            };
            action = doc.action;
          }
          callback(!query.empty, action, data);
        },
        (err) => console.log("query err", err)
      );
      return sub;
    } catch (error) {}
  };

export const getJobCount = (col, date, callback) => async () => {
  const { query, collection, where, getDocs, getFirestore } = await import(
    "firebase/firestore"
  );
  const { getStamp } = await import("../utils/FbStamp");
  const db = getFirestore(firebase);
  const start_date = getStamp(date);
  const q = query(collection(db, col), where("posted", ">=", start_date));
  const querySnap = await getDocs(q);
  callback(querySnap.size < 10);
};
export const getJobApplications = (type, callback, field) => async () => {
  const posts = [];
  let q;
  const {
    query,
    collectionGroup,
    orderBy,
    where,
    collection,
    onSnapshot,
    getFirestore,
  } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  if (field) {
    q = query(
      collectionGroup(db, type),
      orderBy("posted", "desc"),
      //where('paid', '==', true),
      where(field.key, "==", field.value)
    );
  } else {
    q = query(collection(db, type), orderBy("posted", "desc"));
  }

  const sub = onSnapshot(
    q,
    (query) => {
      console.log("query getJobApplications ", query.size);

      query.docChanges().forEach(async (change) => {
        const data = change.doc.data();
        console.log("query data ", data);

        if (change.type === "added") {
          const dataSet = type.includes("jobs")
            ? {
                ...data,
                posted: data.posted ? data.posted.toDate() : new Date(),
                start_date: data.start_date
                  ? data.start_date.toDate()
                  : new Date(),
              }
            : {
                ...data,
                posted: data.posted ? data.posted.toDate() : new Date(),
              };
          posts.push(dataSet);
        } else if (change.type === "modified") {
          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );
          const dataSet = type.includes("jobs")
            ? {
                ...data,
                posted: data.posted ? data.posted.toDate() : new Date(),
                start_date: data.start_date
                  ? data.start_date.toDate()
                  : new Date(),
              }
            : {
                ...data,
                posted: data.posted ? data.posted.toDate() : new Date(),
              };
          posts.splice(position, 1, dataSet);
          console.log("query data modified ", posts, "pos ", position);
        } else if (change.type === "removed") {
          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );
          posts.splice(position, 1);
        }
      });
      console.log("query posts ", posts);

      callback(posts);
    },
    (err) => console.log("query err", err)
  );
  return sub;
};
export const getFeatured = (callback) => async () => {
  const posts = [];
  let dt = new Date();
  const { query, collection, orderBy, where, limit, onSnapshot, getFirestore } =
    await import("firebase/firestore");
  const { getStamp } = await import("../utils/FbStamp");
  const db = getFirestore(firebase);
  dt.setDate(dt.getDate() - 1);
  const timestamp_now = getStamp(dt);
  let dt1 = new Date();

  dt1.setDate(dt1.getDate() + 1);
  const timestamp_nxt = getStamp(dt1);

  const q = query(
    collection(db, "users"),
    orderBy("featured", "desc"),
    where("featured", ">", timestamp_now),
    where("featured", "<", timestamp_nxt),
    where("live", "==", true),
    where("isCompelete", "==", true),
    limit(15)
  );
  const sub = onSnapshot(
    q,
    (query) => {
      console.log("query ", query.size);

      query.docChanges().forEach(async (change) => {
        const data = change.doc.data();
        console.log("query data ", data);

        if (change.type === "added") {
          posts.push({
            ...data,
            date_created: data.date_created.toDate(),
            date_updated: data.date_updated.toDate(),
            start_date: data.start_date ? data.start_date.toDate() : new Date(),
            featured: data.featured.toDate(),
          });
        } else if (change.type === "modified") {
          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );

          posts.splice(position, 1, {
            ...data,
            date_created: data.date_created.toDate(),
            date_updated: data.date_updated.toDate(),
            start_date: data.start_date ? data.start_date.toDate() : new Date(),
            featured: data.featured.toDate(),
          });
          console.log("query data modified ", posts, "pos ", position);
        } else if (change.type === "removed") {
          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );
          posts.splice(position, 1);
        }
      });
      ///const featured = posts.filter(post=>new Date(post.featured).toString().substring(4, 15)===new Date().toString().substring(4, 15));
      console.log("query posts ", posts);

      callback(posts);
    },
    (err) => console.log("query err", err)
  );
  return sub;
};
export const getJobPost = (col, post_id, callback) => async () => {
  console.log("group post_id ", post_id);
  const { query, collectionGroup, onSnapshot, where, getFirestore } =
    await import("firebase/firestore");
  const db = getFirestore(firebase);
  const q = query(collectionGroup(db, col), where("post_id", "==", post_id));
  const sub = onSnapshot(
    q,
    (query) => {
      console.log("query ", query.size);

      const change = query.docChanges()[0];
      const data = change.doc.data();
      console.log("query data ", data);

      if (change.type === "added" || change.type === "modified") {
        const res =
          col === "posts" || col === "carts" || col === "orders"
            ? {
                ...data,

                posted: data.posted ? data.posted.toDate() : new Date(),
              }
            : {
                ...data,
                posted: data.posted ? data.posted.toDate() : new Date(),
                start_date: data.start_date
                  ? data.start_date.toDate()
                  : new Date(),
              };
        callback(res);
      } else if (change.type === "removed") {
        callback();
      }
    },
    (err) => console.log("query err", err)
  );
  return sub;
  //
};

export const addLikeViewPostComment =
  (path, exists, callback, payload) => async () => {
    try {
      const { deleteDoc, doc, setDoc, getFirestore } = await import(
        "firebase/firestore"
      );
      const db = getFirestore(firebase);
      const col = doc(db, path);
      if (exists) {
        await deleteDoc(col);
      } else {
        await setDoc(col, payload || { user: true }, { merge: true });
      }
      if (callback) callback(false);
    } catch (error) {}
  };
export const isLikedViewPostComment =
  (user_id, post_id, action_id, callback, comment_id) => async () => {
    const path = comment_id
      ? `users/${user_id}/posts/${post_id}/comments/${comment_id}/likes`
      : `users/${user_id}/posts/${post_id}/likes`;
    const { onSnapshot, doc, getFirestore } = await import(
      "firebase/firestore"
    );
    const db = getFirestore(firebase);
    try {
      const sub = onSnapshot(doc(db, path, action_id), (doc) => {
        if (callback) callback(doc.exists());
      });
      return sub;
    } catch (error) {}
  };
export const getPosts = (callback) => async () => {
  const posts = [];
  const { query, collectionGroup, orderBy, onSnapshot, getFirestore } =
    await import("firebase/firestore");
  const db = getFirestore(firebase);
  const q = query(collectionGroup(db, "posts"), orderBy("posted", "desc"));

  const sub = onSnapshot(
    q,
    (query) => {
      console.log("query ", query.size);

      query.docChanges().forEach(async (change) => {
        const data = change.doc.data();
        console.log("query data ", data);

        if (change.type === "added") {
          posts.push({
            ...data,

            posted: data.posted ? data.posted.toDate() : new Date(),
          });
        } else if (change.type === "modified") {
          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );

          posts.splice(position, 1, {
            ...data,

            posted: data.posted ? data.posted.toDate() : new Date(),
          });
        } else if (change.type === "removed") {
          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );
          posts.splice(position, 1);
        }
      });
      console.log("query posts ", posts);

      callback(posts);
    },
    (err) => console.log("query err", err)
  );
  return sub;
};
export const getPostsComments = (user_id, post_id, callback) => async () => {
  const posts = [];
  const { query, collection, orderBy, onSnapshot, getFirestore } = await import(
    "firebase/firestore"
  );
  const db = getFirestore(firebase);
  const q = query(
    collection(db, `users/${user_id}/posts/${post_id}/comments`),
    orderBy("posted", "desc")
  );

  const sub = onSnapshot(
    q,
    (query) => {
      console.log("query ", query.size);

      query.docChanges().forEach(async (change) => {
        const data = change.doc.data();
        console.log("query data ", data);

        if (change.type === "added") {
          console.log("snap added data ", data.post_id);

          posts.push({
            ...data,

            posted: data.posted ? data.posted.toDate() : new Date(),
          });
        } else if (change.type === "modified") {
          console.log("snap modified data ", data.post_id);

          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );

          posts.splice(position, 1, {
            ...data,

            posted: data.posted ? data.posted.toDate() : new Date(),
          });
        } else if (change.type === "removed") {
          console.log("snap removed data ", data.post_id);

          const position = posts.findIndex(
            (item) => item.post_id === data.post_id
          );
          posts.splice(position, 1);
        }
      });
      console.log("query posts ", posts);

      callback(posts);
    },
    (err) => console.log("query err", err)
  );
  return sub;
};
export const saveJobToFirestore =
  (form, callback, action, post_id) => async () => {
    console.log("saving to fb why ", form);
    const { doc, setDoc, serverTimestamp, getFirestore } = await import(
      "firebase/firestore"
    );
    const db = getFirestore(firebase);
    const { getStamp } = await import("../utils/FbStamp");
    try {
      const col = `users/${form.user_id}/jobs`;
      const start_date = getStamp(form.start_date);

      if (post_id) {
        await setDoc(doc(db, col, post_id), { ...form, start_date });
      } else {
        const post_id = useUUUID();
        await setDoc(doc(db, col, post_id), {
          ...form,
          posted: serverTimestamp(),
          post_id,
          views: 0,
          paid: false,
          live: false,
          pending: true,
          start_date,
        });
      }
      callback(action);
    } catch (err) {
      console.log("saving to fb why null ", err);
    }
  };
export const saveApplicationToFirestore =
  (form, callback, post_id, apply) => async () => {
    console.log("saving to fb why ", form);
    const {
      serverTimestamp,
      query,
      collection,
      setDoc,
      getDocs,
      where,
      limit,
      doc,
      getFirestore,
    } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    const { getStamp } = await import("../utils/FbStamp");
    try {
      //const col = db.collection('users').doc(form.user_id).collection(apply);
      const start_date = getStamp(form.start_date);

      if (post_id) {
        await setDoc(doc(db, apply, post_id), form, { merge: true });
        callback(form);
      } else {
        const post_id = useUUUID();
        const data = {
          ...form,
          posted: serverTimestamp(),
          start_date,
          post_id,
        };
        if (apply.includes("carts")) {
          const q = query(
            collection(db, apply),
            where("name", "==", form.name),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          const name = !querySnapshot.empty
            ? form.name + useUUUID().substring(0, 4)
            : form.name;
          await setDoc(doc(db, apply, post_id), { ...data, name });
        } else {
          await setDoc(doc(db, apply, post_id), data);
        }

        callback(data);
      }
    } catch (err) {
      console.log("saving to fb why null ", err);
    }
  };
export const saveOrderToFirestore =
  (form, position, col, callback) => async () => {
    console.log("saving to fb why ", form);
    const { setDoc, serverTimestamp, GeoPoint, doc, getFirestore } =
      await import("firebase/firestore");
    const db = getFirestore(firebase);
    try {
      await setDoc(doc(db, col, form.post_id), {
        ...form,
        posted: serverTimestamp(),
        geoPoint: new GeoPoint(
          position.coords.latitude,
          position.coords.longitude
        ),
      });
      if (callback) callback();
    } catch (err) {}
  };
export const takeOrderFirestore =
  (form, position, col, order, callback) => async () => {
    console.log("saving to fb why ", form);
    const { runTransaction, serverTimestamp, GeoPoint, getFirestore } =
      await import("firebase/firestore");
    const db = getFirestore(firebase);
    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(order);
        if (!sfDoc.exists()) {
          throw "Document does not exist!";
        }
        if (sfDoc.data().pending) {
          transaction.update(order, {
            pending: false,
            cook_id: col.split("/")[1],
          });
          transaction.set(col, {
            pending: false,
            cook_id: col.split("/")[1],
            posted: serverTimestamp(),
            geoPoint: new GeoPoint(
              position.coords.latitude,
              position.coords.longitude
            ),
          });
          if (callback) callback(true);
        } else {
          if (callback) callback(false);
        }
      });
      console.log("Transaction successfully committed!");
    } catch (err) {}
  };
export const saveItemToFirestore =
  (form, callback, colRef, edit) => async () => {
    console.log("saving to fb why ", form);
    const { getDoc, setDoc, doc, getFirestore } = await import(
      "firebase/firestore"
    );
    const { getStamp } = await import("../utils/FbStamp");
    const db = getFirestore(firebase);
    try {
      const posted = getStamp(form.posted);

      const post_id = form.post_id;
      let isEdit = false;
      let querySnapshot;
      if (!edit) {
        querySnapshot = await getDoc(doc(db, colRef, post_id));
        console.log("saving to fb why querySnapshot ", querySnapshot.exists());
        isEdit = querySnapshot.exists();
      }

      const data = isEdit
        ? {
            ...form,
            posted,
            qty: form.qty + querySnapshot.data().qty,
            total: form.total + querySnapshot.data().total,
          }
        : {
            ...form,
            posted,
          };
      await setDoc(doc(db, colRef, post_id), data);

      callback();
    } catch (err) {
      console.log("saving to fb why null ", err);
    }
  };
const saveToMail = (to, name, data) => async () => {
  const post_id = useUUUID();
  const { setDoc, doc, getFirestore } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  await setDoc(doc(db, "mail", post_id), {
    to,
    template: {
      name,
      data,
    },
  });
};
export const saveContactForm = (to, name, data, callback) => async () => {
  saveToMail(to, name, data);
  callback();
};

export const savePostToFirestore = (form, callback, post_id) => async () => {
  console.log("saving to fb why ", form, "post_id ", post_id);
  const { setDoc, doc, getFirestore } = await import("firebase/firestore");
  const { getStamp } = await import("../utils/FbStamp");
  const db = getFirestore(firebase);
  try {
    const col = `users/${form.user_id}/posts`;
    let passover = form;
    const posted = getStamp(form.posted);
    if (post_id) {
      await setDoc(doc(db, col, post_id), { ...form, posted })
        .then(() => console.log("saving to fb successful ", form))
        .catch((err) => console.log("saving to fb err ", err));
    } else {
      const post_id = useUUUID();
      passover = { ...form, post_id };
      await setDoc(doc(db, col, post_id), {
        ...form,
        posted,
        post_id,
        views: 0,
        comments: 0,
        likes: 0,
      });
    }
    callback(passover);
  } catch (err) {
    console.log("saving to fb why null ", err);
  }
};
export const saveCommentToFirestore =
  (form, callback, comment_id) => async () => {
    console.log("saving to fb why ", form, "post_id ", comment_id);
    const { setDoc, doc, getFirestore } = await import("firebase/firestore");
    const { getStamp } = await import("../utils/FbStamp");
    const db = getFirestore(firebase);
    try {
      const col = `users/${form.user_path_id}/posts/${form.post_id}/comments`;

      let passover = form;
      if (comment_id) {
        const posted = getStamp(form.posted);
        await setDoc(doc(db, col, comment_id), { ...form, posted })
          .then(() => console.log("saving to fb successful ", form))
          .catch((err) => console.log("saving to fb err ", err));
      } else {
        const posted = getStamp(new Date());

        const comment_id = useUUUID();
        passover = { ...form, comment_id };
        await setDoc(doc(db, col, comment_id), {
          ...form,
          posted,
          comment_id,
          likes: 0,
        });
      }
      callback(passover);
    } catch (err) {
      console.log("saving to fb why null ", err);
    }
  };
const fetchFirstJobs = async (
  form,
  filter1,
  comboFilter,
  comboSize,
  resCallback,
  total
) => {
  const promises = [];
  const { getDocs, collection, query, orderBy, where, limit, getFirestore } =
    await import("firebase/firestore");
  const db = getFirestore(firebase);
  filter1.forEach((entry) => {
    const q = getDocs(
      query(
        collection(db, "users"),
        orderBy("date_updated", "desc"),
        where("live", "==", true),
        where("isCompelete", "==", true),
        where(entry, "array-contains", form[entry]),
        limit(15)
      )
    );
    promises.push(q);
  });
  if (comboSize) {
    try {
      comboFilter.forEach((entry) => {
        console.log("isCompelete add promises ", entry);

        const q = getDocs(
          query(
            collection(db, "users"),
            orderBy("date_updated", "desc"),
            where("live", "==", true),
            where("isCompelete", "==", true),
            where(entry, "==", form[entry]),
            limit(15)
          )
        );
        promises.push(q);
      });
    } catch (err) {}
  } else if (filter1.length === 0) {
    const q = getDocs(
      query(
        collection(db, "users"),
        orderBy("date_updated", "desc"),
        where("live", "==", true),
        where("isCompelete", "==", true),
        limit(15)
      )
    );
    promises.push(q);
  }
  console.log("isCompelete promises ", promises.length);

  return Promise.all(promises).then((results) => {
    let posts = {};
    console.log("isCompelete results ", results.length);

    results.forEach((query) => {
      console.log("isCompelete query ", query.size);

      query.forEach((doc) => {
        const data = doc.data();
        console.log("isCompelete ", data.isCompelete);
        const user = {
          ...data,
          date_created: data.date_created.toDate(),
          date_updated: data.date_updated.toDate(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        };
        posts = { ...posts, [doc.id]: user };
      });
    });
    console.log("isCompelete posts ", posts);

    const resFilter = Object.values(posts).filter((entry) => {
      const comp2 = pick(entry, comboFilter);
      console.log("isCompelete compare ", comp2);

      if (comboSize) {
        if (
          includes(filter1, "languages") &&
          includes(filter1, "education") &&
          includes(filter1, "positions")
        ) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "positions")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions)
          );
        } else {
          return isEqual(comp2, pick(form, comboFilter));
        }
      } else {
        if (includes(filter1, "languages") && includes(filter1, "positions")) {
          return (
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return includes(entry.languages, form.languages);
        } else if (includes(filter1, "positions")) {
          return includes(entry.positions, form.positions);
        } else {
          return true;
        }
      }
    });

    resCallback(resFilter, total, true);
  });
};
const fetchInitJobs = async (
  form,
  filter1,
  comboFilter,
  comboSize,
  resCallback
) => {
  const promises = [];
  const { getDocs, query, collection, orderBy, where, getFirestore } =
    await import("firebase/firestore");
  const db = getFirestore(firebase);
  filter1.forEach((entry) => {
    const q = getDocs(
      query(
        collection(db, "users"),
        orderBy("date_updated", "desc"),
        where("live", "==", true),
        where("isCompelete", "==", true),
        where(entry, "array-contains", form[entry])
      )
    );
    promises.push(q);
  });
  if (comboSize) {
    try {
      comboFilter.forEach((entry) => {
        console.log("isCompelete add promises ", entry);

        const q = getDocs(
          query(
            collection(db, "users"),
            orderBy("date_updated", "desc"),
            where("live", "==", true),
            where("isCompelete", "==", true),
            where(entry, "==", form[entry])
          )
        );
        promises.push(q);
      });
    } catch (err) {}
  } else if (filter1.length === 0) {
    const q = getDocs(
      query(
        collection(db, "users"),
        orderBy("date_updated", "desc"),
        where("live", "==", true),
        where("isCompelete", "==", true)
      )
    );
    promises.push(q);
  }
  console.log("isCompelete promises ", promises.length);

  return Promise.all(promises).then((results) => {
    let posts = {};
    console.log("isCompelete results ", results.length);

    results.forEach((query) => {
      console.log("isCompelete query ", query.size);

      query.forEach((doc) => {
        const data = doc.data();
        console.log("isCompelete ", data.isCompelete);
        const user = {
          ...data,
          date_created: data.date_created.toDate(),
          date_updated: data.date_updated.toDate(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        };
        posts = { ...posts, [doc.id]: user };
      });
    });
    console.log("isCompelete posts init ", posts);

    const resFilter = Object.values(posts).filter((entry) => {
      const comp2 = pick(entry, comboFilter);
      console.log("isCompelete compare init ", comp2, " comboSize ", comboSize);

      if (comboSize) {
        console.log(
          "isCompelete compare pick(form, comboFilter) ",
          pick(form, comboFilter),
          " comboFilter ",
          comboFilter,
          " filter1 ",
          filter1
        );
        if (
          includes(filter1, "languages") &&
          includes(filter1, "education") &&
          includes(filter1, "positions")
        ) {
          console.log("isCompelete compare filter1 ", filter1);

          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          console.log("isCompelete compare filter12 ", filter1);
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "positions")) {
          console.log("isCompelete compare filter13 ", filter1);
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions)
          );
        } else {
          console.log(
            "isCompelete compare comp2 ",
            comp2,
            " pick(form, comboFilter) ",
            pick(form, comboFilter)
          );

          return isEqual(comp2, pick(form, comboFilter));
        }
      } else {
        if (includes(filter1, "languages") && includes(filter1, "positions")) {
          return (
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return includes(entry.languages, form.languages);
        } else if (includes(filter1, "positions")) {
          return includes(entry.positions, form.positions);
        } else {
          return true;
        }
      }
    });
    console.log("isCompelete compare resFilter.length ", resFilter.length);

    if (resFilter.length > 0) {
      fetchFirstJobs(
        form,
        filter1,
        comboFilter,
        comboSize,
        resCallback,
        resFilter.length
      );
    } else {
      resCallback(resFilter, resFilter.length, true);
    }
  });
};
const fetchMoreJobs = async (
  form,
  filter1,
  comboFilter,
  comboSize,
  resCallback,
  lastDoc
) => {
  const {
    getDocs,
    getDoc,
    query,
    collection,
    orderBy,
    where,
    startAfter,
    limit,
    doc,
    getFirestore,
  } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  const afterDoc = await getDoc(doc(db, "user", lastDoc));
  const promises = [];
  console.log("isCompelete form after ", lastDoc);

  filter1.forEach((entry) => {
    const q = getDocs(
      query(
        collection(db, "users"),
        orderBy("date_updated", "desc"),
        where("live", "==", true),
        where("isCompelete", "==", true),
        where(entry, "array-contains", form[entry]),
        startAfter(afterDoc),
        limit(15)
      )
    );
    promises.push(q);
  });
  if (comboSize) {
    try {
      comboFilter.forEach((entry) => {
        console.log("isCompelete add promises ", entry);

        const q = getDocs(
          query(
            collection(db, "users"),
            orderBy("date_updated", "desc"),
            where("live", "==", true),
            where("isCompelete", "==", true),
            where(entry, "==", form[entry]),
            startAfter(afterDoc),
            limit(15)
          )
        );
        promises.push(q);
      });
    } catch (err) {}
  } else if (filter1.length === 0) {
    const q = getDocs(
      query(
        collection(db, "users"),
        orderBy("date_updated", "desc"),
        where("live", "==", true),
        where("isCompelete", "==", true),
        startAfter(afterDoc),
        limit(15)
      )
    );
    promises.push(q);
  }
  console.log("isCompelete promises ", promises.length);

  return Promise.all(promises).then((results) => {
    let posts = {};
    console.log("isCompelete results ", results.length);

    results.forEach((query) => {
      console.log("isCompelete query ", query.size);

      query.forEach((doc) => {
        const data = doc.data();
        console.log("isCompelete ", data.isCompelete);
        const user = {
          ...data,
          date_created: data.date_created.toDate(),
          date_updated: data.date_updated.toDate(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        };
        posts = { ...posts, [doc.id]: user };
      });
    });
    console.log("isCompelete posts ", posts);

    const resFilter = Object.values(posts).filter((entry) => {
      const comp2 = pick(entry, comboFilter);
      console.log("isCompelete compare ", comp2);

      if (comboSize) {
        if (
          includes(filter1, "languages") &&
          includes(filter1, "education") &&
          includes(filter1, "positions")
        ) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "positions")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions)
          );
        } else {
          return isEqual(comp2, pick(form, comboFilter));
        }
      } else {
        if (includes(filter1, "languages") && includes(filter1, "positions")) {
          return (
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return includes(entry.languages, form.languages);
        } else if (includes(filter1, "positions")) {
          return includes(entry.positions, form.positions);
        } else {
          return true;
        }
      }
    });

    resCallback(resFilter);
  });
};
export const fetchJobsResults = (resCallback, form, lastDoc) => async () => {
  console.log("isCompelete form ", form);

  const keys = Object.keys(form);
  const filter1 = keys.filter(
    (entry) =>
      (entry === "positions" && form[entry] !== "Any Position") ||
      (entry === "education" && form[entry] !== "Any Level") ||
      (entry === "languages" && form[entry] !== "Any Language")
  );

  const filter2 = [
    "driver",
    "car",
    "pets",
    "sex",
    "cpr",
    "smoker",
    "live_in_out",
    "full_part_time",
    "work_type",
  ].filter((entry) => form[entry] !== "Either");

  const filter3 = ["experience", "age_range", "number_kids", "duration"].filter(
    (entry) => form[entry] !== "Not specified"
  );
  const filter4 = ["currently_in", "region", "town"].filter(
    (entry) =>
      (entry === "currently_in" && form[entry] !== "Any Country") ||
      (entry === "region" && form[entry] !== "Any Region") ||
      (entry === "town" &&
        form[entry] !== "" &&
        entry === "town" &&
        form[entry] !== "Any Town" &&
        entry === "town" &&
        form[entry] !== "Any town")
  );
  const comboFilter = [...filter2, ...filter3, ...filter4];
  const comboSize = comboFilter.length > 0;
  console.log(
    " isCompelete data filter2 ",
    filter2,
    " isCompelete data filter3 ",
    filter3,
    " isCompelete data filter4 ",
    filter4,
    " isCompelete data comboSize ",
    comboFilter,
    " isCompelete data lastDoc ",
    lastDoc,
    " isCompelete data comboSize ",
    comboSize
  );
  if (!lastDoc) {
    fetchInitJobs(form, filter1, comboFilter, comboSize, resCallback);
  } else {
    fetchMoreJobs(form, filter1, comboFilter, comboSize, resCallback, lastDoc);
  }

  /*  */
};
const fetchFirstOffers = async (
  form,
  filter1,
  comboFilter,
  comboSize,
  resCallback,
  total
) => {
  const promises = [];
  const {
    getDocs,
    query,
    collectionGroup,
    orderBy,
    where,
    limit,
    getFirestore,
  } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  filter1.forEach((entry) => {
    const q = getDocs(
      query(
        collectionGroup(db, "jobs"),
        orderBy("posted", "desc"),
        where("paid", "==", true),
        where("live", "==", true),
        where(entry, "array-contains", form[entry]),
        limit(15)
      )
    );
    promises.push(q);
  });

  if (comboSize) {
    try {
      comboFilter.forEach((entry) => {
        console.log("isCompelete add promises ", entry);

        const q = getDocs(
          query(
            collectionGroup(db, "jobs"),
            orderBy("posted", "desc"),
            where("paid", "==", true),
            where("live", "==", true),
            where(entry, "==", form[entry]),
            limit(15)
          )
        );
        promises.push(q);
      });
    } catch (err) {}
  } else if (filter1.length === 0) {
    const q = getDocs(
      query(collectionGroup(db, "jobs"), orderBy("posted", "desc"), limit(15))
    );
    promises.push(q);
  }
  console.log("isCompelete promises ", promises.length);

  return Promise.all(promises).then((results) => {
    let posts = {};
    console.log("isCompelete results ", results.length);

    results.forEach((query) => {
      console.log("isCompelete query ", query.size);

      query.forEach((doc) => {
        const data = doc.data();
        console.log("isCompelete ", data);
        const user = {
          ...data,
          date_created: data.posted ? data.posted.toDate() : new Date(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        };
        posts = { ...posts, [doc.id]: user };
      });
    });
    console.log("isCompelete posts ", posts);

    const resFilter = Object.values(posts).filter((entry) => {
      const comp2 = pick(entry, comboFilter);
      console.log("isCompelete compare ", comp2);

      if (comboSize) {
        if (
          includes(filter1, "languages") &&
          includes(filter1, "education") &&
          includes(filter1, "positions")
        ) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "positions")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions)
          );
        } else {
          return isEqual(comp2, pick(form, comboFilter));
        }
      } else {
        if (includes(filter1, "languages") && includes(filter1, "positions")) {
          return (
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return includes(entry.languages, form.languages);
        } else if (includes(filter1, "positions")) {
          return includes(entry.positions, form.positions);
        } else {
          return true;
        }
      }
    });
    console.log("isCompelete resFilter ", resFilter);

    resCallback(resFilter, total, true);
  });
};
const fetchInitOffers = async (
  form,
  filter1,
  comboFilter,
  comboSize,
  resCallback
) => {
  const promises = [];
  const { getDocs, query, collectionGroup, orderBy, where, getFirestore } =
    await import("firebase/firestore");
  const db = getFirestore(firebase);
  filter1.forEach((entry) => {
    const q = getDocs(
      query(
        collectionGroup(db, "jobs"),
        orderBy("posted", "desc"),
        where("paid", "==", true),
        where("live", "==", true),
        where(entry, "array-contains", form[entry])
      )
    );
    promises.push(q);
  });

  if (comboSize) {
    try {
      comboFilter.forEach((entry) => {
        console.log("isCompelete add promises ", entry);

        const q = getDocs(
          query(
            collectionGroup(db, "jobs"),
            orderBy("posted", "desc"),
            where("paid", "==", true),
            where("live", "==", true),
            where(entry, "==", form[entry])
          )
        );
        promises.push(q);
      });
    } catch (err) {}
  } else if (filter1.length === 0) {
    const q = getDocs(
      query(collectionGroup(db, "jobs"), orderBy("posted", "desc"))
    );
    promises.push(q);
  }
  console.log("isCompelete promises ", promises.length);

  return Promise.all(promises).then((results) => {
    let posts = {};
    console.log("isCompelete results ", results.length);

    results.forEach((query) => {
      console.log("isCompelete query ", query.size);

      query.forEach((doc) => {
        const data = doc.data();
        console.log("isCompelete ", data);
        const user = {
          ...data,
          date_created: data.posted ? data.posted.toDate() : new Date(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        };
        posts = { ...posts, [doc.id]: user };
      });
    });
    console.log("isCompelete posts ", posts);

    const resFilter = Object.values(posts).filter((entry) => {
      const comp2 = pick(entry, comboFilter);
      console.log("isCompelete compare ", comp2);

      if (comboSize) {
        if (
          includes(filter1, "languages") &&
          includes(filter1, "education") &&
          includes(filter1, "positions")
        ) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "positions")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions)
          );
        } else {
          return isEqual(comp2, pick(form, comboFilter));
        }
      } else {
        if (includes(filter1, "languages") && includes(filter1, "positions")) {
          return (
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return includes(entry.languages, form.languages);
        } else if (includes(filter1, "positions")) {
          return includes(entry.positions, form.positions);
        } else {
          return true;
        }
      }
    });
    if (resFilter.length > 0) {
      fetchFirstOffers(
        form,
        filter1,
        comboFilter,
        comboSize,
        resCallback,
        resFilter.length
      );
    } else {
      resCallback(resFilter, resFilter.length, true);
    }
  });
};

const fetchMoreOffers = async (
  form,
  filter1,
  comboFilter,
  comboSize,
  resCallback,
  lastDoc
) => {
  const { user_id, post_id } = lastDoc;
  const {
    getDocs,
    getDoc,
    query,
    collectionGroup,
    orderBy,
    where,
    startAfter,
    limit,
    doc,
    getFirestore,
  } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  const afterDoc = await getDoc(doc(db, `users/${user_id}/jobs`, post_id));
  const promises = [];

  filter1.forEach((entry) => {
    const q = getDocs(
      query(
        collectionGroup(db, "jobs"),
        orderBy("posted", "desc"),
        where("paid", "==", true),
        where("live", "==", true),
        where(entry, "array-contains", form[entry]),
        startAfter(afterDoc),
        limit(15)
      )
    );
    promises.push(q);
  });

  if (comboSize) {
    try {
      comboFilter.forEach((entry) => {
        console.log("isCompelete add promises ", entry);

        const q = getDocs(
          query(
            collectionGroup(db, "jobs"),
            orderBy("posted", "desc"),
            where("paid", "==", true),
            where("live", "==", true),
            where(entry, "==", form[entry]),
            startAfter(afterDoc),
            limit(15)
          )
        );
        promises.push(q);
      });
    } catch (err) {}
  } else if (filter1.length === 0) {
    const q = getDocs(
      query(
        collectionGroup(db, "jobs"),
        orderBy("posted", "desc"),
        startAfter(afterDoc),
        limit(15)
      )
    );
    promises.push(q);
  }
  console.log("isCompelete promises ", promises.length);

  return Promise.all(promises).then((results) => {
    let posts = {};
    console.log("isCompelete results ", results.length);

    results.forEach((query) => {
      console.log("isCompelete query ", query.size);

      query.forEach((doc) => {
        const data = doc.data();
        console.log("isCompelete ", data);
        const user = {
          ...data,
          date_created: data.posted ? data.posted.toDate() : new Date(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        };
        posts = { ...posts, [doc.id]: user };
      });
    });
    console.log("isCompelete posts ", posts);

    const resFilter = Object.values(posts).filter((entry) => {
      const comp2 = pick(entry, comboFilter);
      console.log("isCompelete compare ", comp2);

      if (comboSize) {
        if (
          includes(filter1, "languages") &&
          includes(filter1, "education") &&
          includes(filter1, "positions")
        ) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "positions")) {
          return (
            isEqual(comp2, pick(form, comboFilter)) &&
            includes(entry.positions, form.positions)
          );
        } else {
          return isEqual(comp2, pick(form, comboFilter));
        }
      } else {
        if (includes(filter1, "languages") && includes(filter1, "positions")) {
          return (
            includes(entry.positions, form.positions) &&
            includes(entry.languages, form.languages)
          );
        } else if (includes(filter1, "languages")) {
          return includes(entry.languages, form.languages);
        } else if (includes(filter1, "positions")) {
          return includes(entry.positions, form.positions);
        } else {
          return true;
        }
      }
    });
    resCallback(resFilter);
  });
};
export const fetchOfferResults = (resCallback, form, lastDoc) => async () => {
  console.log("isCompelete form ", form);

  const keys = Object.keys(form);
  const filter1 = keys.filter(
    (entry) =>
      (entry === "positions" && form[entry] !== "Any Position") ||
      (entry === "education" && form[entry] !== "Any Level") ||
      (entry === "languages" && form[entry] !== "Any Language")
  );
  const filter2 = ["live_in_out", "full_part_time", "temp_permanent"].filter(
    (entry) => form[entry] !== "Both"
  );

  const filter3 = [
    "experience",
    "driver",
    "pets",
    "cpr",
    "smoker",
    "pets",
    "age_range",
    "number_kids",
  ].filter((entry) => form[entry] !== "Not specified");
  const filter4 = ["currently_in", "region", "sex", "educated", "town"].filter(
    (entry) =>
      (entry === "educated" && form[entry] !== "Any Level") ||
      (entry === "sex" && form[entry] !== "Either") ||
      (entry === "currently_in" && form[entry] !== "Any Country") ||
      (entry === "region" && form[entry] !== "Any Region") ||
      (entry === "town" &&
        form[entry] !== "" &&
        form[entry] !== "Any Town" &&
        form[entry] !== "Any town")
  );
  const comboFilter = [...filter2, ...filter3, ...filter4];
  const comboSize = comboFilter.length > 0;
  console.log(
    "isCompelete data filter2 ",
    filter2,
    "isCompelete data filter3 ",
    filter3,
    "isCompelete data filter4 ",
    filter4,
    "isCompelete data comboSize ",
    comboFilter
  );
  if (!lastDoc) {
    fetchInitOffers(form, filter1, comboFilter, comboSize, resCallback);
  } else {
    fetchMoreOffers(
      form,
      filter1,
      comboFilter,
      comboSize,
      resCallback,
      lastDoc
    );
  }

  /*  */
};
export const fetchTotalJobsResults =
  (collection, currently_in, callback) => async (dispatch) => {
    const { onSnapshot, doc, getFirestore } = await import(
      "firebase/firestore"
    );
    const db = getFirestore(firebase);
    try {
      const sub = onSnapshot(doc(db, collection, currently_in), (doc) => {
        if (doc.exists()) {
          const { total = 0 } = doc.data() || {};
          if (callback) {
            callback(total);
          } else {
            dispatch({
              type: TOTAL,
              payload: total,
            });
          }
        }
      });
      return sub;
    } catch (err) {}
  };
export const fetchCurrentTotalJobsResults =
  (col, currently_in, town, resCallback) => async () => {
    try {
      let jobs = [];
      console.log("added => started ", currently_in, "town ", town);
      const { onSnapshot, query, collection, where, getFirestore } =
        await import("firebase/firestore");
      const db = getFirestore(firebase);
      const q = query(
        collection(db, `${col}/${currently_in}/regions`),
        where("total", ">", 0)
      );

      const sub = onSnapshot(
        q,
        (querySnapshot) => {
          console.log(
            "added => exist ",
            querySnapshot.exists,
            "size ",
            querySnapshot.size
          );

          querySnapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              // doc.data() is never undefined for query doc snapshots
              console.log("added => ", change.doc.data());

              jobs = [...jobs, change.doc.data()];
            }
          });
          let sorted = orderByLodash(jobs, ["post_id"], ["asc"]);

          if (includes(map(sorted, "post_id"), town)) {
            const index = findIndex(sorted, { post_id: town });
            const payload = find(sorted, { post_id: town });
            console.log("added => includes ", index);
            sorted.splice(index, 1);
            sorted = [payload, ...sorted];
          }
          resCallback(sorted);
        },
        (err) => {
          console.log("added => err ", err);
        }
      );
      return sub;
    } catch (err) {
      console.log("added => err ", err);
    }
  };
export const saveGen = (post, payload) => async () => {
  const { setDoc, doc, getFirestore } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  setDoc(doc(db, post), payload);
};
export const fetchLimit = () => async (dispatch) => {
  const { onSnapshot, doc, getFirestore } = await import("firebase/firestore");
  const db = getFirestore(firebase);
  const sub = onSnapshot(doc(db, "contact_limit/limit"), (query) => {
    const { val = 10 } = query.data() || {};
    console.log("limitted ", val);
    return dispatch({
      type: LIMIT,
      payload: val,
    });
  });
  return sub;
};
export const fetchAllTotalJobsResults =
  (col, currently_in, resCallback) => async () => {
    console.log("fetchAllTotalJobsResults ", col);
    try {
      let jobs = [];
      const { onSnapshot, query, collection, where, getFirestore } =
        await import("firebase/firestore");
      const db = getFirestore(firebase);
      const q = query(
        collection(db, col),

        where("total", ">", 0)
      );
      const sub = onSnapshot(
        q,
        (querySnapshot) => {
          querySnapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              // doc.data() is never undefined for query doc snapshots
              console.log(
                change.doc.id,
                " => ",
                change.doc.data(),
                "length ",
                jobs.length
              );

              jobs = [...jobs, change.doc.data()];
            }
          });
          let sorted = orderByLodash(jobs, ["post_id"], ["asc"]);

          if (includes(map(sorted, "post_id"), currently_in)) {
            const index = findIndex(sorted, { post_id: currently_in });
            const payload = find(sorted, { post_id: currently_in });
            console.log("added => includes ", index);
            sorted.splice(index, 1);
            sorted.splice(1, 0, payload);
          }
          resCallback(sorted);
        },
        (err) => {
          console.log("fetchAllTotalJobsResults added => err ", err);
        }
      );
      return sub;
    } catch (err) {
      console.log("fetchAllTotalJobsResults added => err ", err);
    }
  };
export const getUserData = (callback) => async () => {
  const docs = [];
  const { onSnapshot, query, collection, where, getFirestore } = await import(
    "firebase/firestore"
  );
  const db = getFirestore(firebase);
  const q = query(
    collection(db, "users"),
    where("live", "==", true),
    where("isCompelete", "==", true),
    where("pending", "==", true),
    where("user_type", "==", "childcarer"),
    where("positions", "array-contains", ["cooks"])
  );
  const sub = onSnapshot(q, (querySnapshot) => {
    querySnapshot.docChanges().forEach(function (change) {
      const data = change.doc.data();

      if (change.type === "added") {
        // doc.data() is never undefined for query doc snapshots
        console.log("query size ", " = ", querySnapshot.size);
        docs.push({
          ...data,
          date_created: data.date_created.toDate(),
          date_updated: data.date_updated.toDate(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        });
      }
      if (change.type === "modified") {
        const position = docs.findIndex(
          (item) => item.post_id === change.doc.data().post_id
        );

        docs.splice(position, 1);

        docs.push({
          ...data,
          date_created: data.date_created.toDate(),
          date_updated: data.date_updated.toDate(),
          start_date: data.start_date ? data.start_date.toDate() : new Date(),
        });
      }
      if (change.type === "removed") {
        const position = docs.findIndex(
          (item) => item.post_id === change.doc.data().post_id
        );

        docs.splice(position, 1);
      }
    });
    callback(docs);
  });
  return sub;
};
const docRes = (dock, callback, store, dispatch, user_data, col) => {
  if (dock.exists()) {
    let data = dock.data();
    console.log("get doc isCompelete ", data);
    try {
      if (data) {
        if (col === "users") {
          data = {
            ...data,
            date_created: data.date_created.toDate(),
            date_updated: data.date_updated.toDate(),
            start_date: data.start_date ? data.start_date.toDate() : new Date(),
            dob: data.dob ? data.dob.toDate() : new Date(),
            jobs: data.jobs.toDate(),
            featured: data.featured.toDate(),
            notes: data.notes.toDate(),
          };
        }
        if (store) {
          dispatch({
            type: UPDATE,
            payload: data,
          });
        }
        if (callback !== null) {
          console.log("get doc callback ", data);

          callback(data, user_data);
        }
      } else {
        console.log("get data err undef ", user_data);

        if (callback !== null) callback(null, user_data);
      }
    } catch (error) {
      console.log("get data err inner ", error.message);
    }
  } else {
    console.log("get data outer ", user_data);

    if (callback !== null) callback(null, user_data);
  }
};
export const getDocFirestore =
  (col, uid, callback, store, user_data) => async (dispatch) => {
    console.log("get doc id isCompelete outer ", col);
    const { onSnapshot, doc, getFirestore } = await import(
      "firebase/firestore"
    );
    const db = getFirestore(firebase);
    try {
      const sub = onSnapshot(
        doc(db, col, uid),
        (dock) => {
          console.log("get data dock ", dock.data());

          docRes(dock, callback, store, dispatch, user_data, col);
        },
        (err) => {
          console.log("get data err ", err.message);
        }
      );
      return sub;
    } catch (err) {
      console.log("get data err why ", err.message);

      callback(null, user_data, err.message);
    }
  };
export const checkDocFirestore =
  (col, uid, callback, store, user_data) => async (dispatch) => {
    console.log("get doc id isCompelete outer ", col);
    const { getDoc, doc, getFirestore } = await import("firebase/firestore");
    const db = getFirestore(firebase);
    try {
      getDoc(doc(db, col, uid)).then((dock) => {
        docRes(dock, callback, store, dispatch, user_data, col);
      });
    } catch (err) {
      console.log("get data err why ", err.message);

      callback(null, user_data, err.message);
    }
  };

export const getReport = (field, opt, post_id, callback) => async () => {
  try {
    const { getDocs, query, collection, where, getFirestore } = await import(
      "firebase/firestore"
    );
    const db = getFirestore(firebase);
    let querySnapshot;
    if (field === "sex" || field === "education") {
      querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("user_type", "==", "childcarer"),
          where("currently_in", "==", post_id),
          where(field, "==", opt)
        )
      );
    } else if (field === "posiions") {
      querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("user_type", "==", "childcarer"),
          where("currently_in", "==", post_id),
          where(field, "array-contains", opt)
        )
      );
    } else {
      const { getStamp } = await import("../utils/FbStamp");
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - (opt + 1));
      const posted = getStamp(oneYearFromNow);

      const yr = new Date();
      yr.setFullYear(yr.getFullYear() - (opt - 1));
      const start = getStamp(yr);

      querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("user_type", "==", "childcarer"),
          where("currently_in", "==", post_id),
          where(field, ">=", start),
          where(field, "<=", posted)
        )
      );
    }
    return callback({ post_id, total: querySnapshot.size });
  } catch (error) {}
};

export const uploadFiles =
  (files, poster, setProgCallback, loopFileCallack, useUser) =>
  async (dispatch) => {
    let i = 0;
    let found = false;
    const promises = [];
    let post = poster;
    console.log("key data ", files, "val ", poster);
    const { getStorage, ref, uploadBytesResumable, getDownloadURL } =
      await import("firebase/storage");
    const storage = getStorage(firebase);
    Object.entries(files).forEach((entry) => {
      const [key, value] = entry;
      console.log("key ", key, "val ", value);

      let uri = value;

      if (uri !== null) {
        console.log("found key ", key, "val ", value);
        try {
          const storageRef = ref(
            storage,
            `${poster.user_id}/media/${useUUUID()}${uri.name}`
          );
          const task = uploadBytesResumable(storageRef, uri);
          found = true;

          promises.push(task);

          task.on(
            "state_changed",
            (taskSnapshot) => {
              console.log(
                `${taskSnapshot.bytesTransferred} transferred out of ${taskSnapshot.totalBytes}`
              );
              const prog =
                (taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100;
              setProgCallback(`Uploading...${i}/(${parseInt(prog)} %)`);
            },
            function (error) {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;

                case "storage/canceled":
                  // User canceled the upload
                  break;

                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
                default:
              }
            },
            async () => {
              const downloadURL = await getDownloadURL(task.snapshot.ref);
              // do something with the url

              post = {
                ...post,
                [key]: downloadURL,
              };
              i++;
              console.log("key counter ", i, "key length ", promises.length);

              if (i === promises.length) {
                Promise.all(promises)
                  .then(() => {
                    console.log("key download found post inner ", post);
                    if (useUser) {
                      dispatch({
                        type: QUERRY_FIRESTORE_SUCCESS,

                        payload: post,
                      });
                    }
                    loopFileCallack(post);
                  })
                  .catch((err) => console.log(err.code));
              }
            }
          );
        } catch (err) {}
      }
    });
    if (!found) {
      console.log("key download outer key post ", post);

      loopFileCallack(post);
    }
  };
